import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { insertCompanies } from "../appRedux/actions/companiesAction";
import { findIndex } from "lodash";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

// import Select from 'react-select';

class CompanyAccountsForm extends React.Component {
  constructor() {
    super();

    // const currentYear = new Date().getFullYear();
    // const years = [];
    // for (let year = currentYear+3; year >= currentYear - 97; year--) {
    //     years.push(year);
    // }
    // this.years=years
    this.state = {
      accountingSoftwareId: "",
      payrollSoftwareId: "",
      infusionsoftContactId: "",
      accountingMethod: "",
      mpwAccountantEmail: "",
      QuickbooksDataSyncBeginningYear: null,
      QuickbooksDataSyncEndingYear: null,
      SyncFrequency: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  handleCancel = () => {
    this.setState({
      accountingSoftwareId: "",
      payrollSoftwareId: "",
      infusionsoftContactId: "",
      accountingMethod: "",
      QuickbooksDataSyncBeginningYear: null,
      QuickbooksDataSyncEndingYear: null,
      SyncFrequency: null
    });

    this.props.handleCancel();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      name: this.props.fstep.name,
      state: this.props.fstep.state,
      city: this.props.fstep.city,
      postalCode: this.props.fstep.postalCode,
      eiNnumber: this.props.fstep.eiNnumber,
      address: this.props.fstep.address,
      isFullServiceBookkeeping: this.props.fstep.isFullServiceBookkeeping,
      isReligeousOrganization: this.props.fstep.isReligeousOrganization,
      QuickbooksDataSyncBeginningYear: this.props.fstep.QuickbooksDataSyncBeginningYear,
      QuickbooksDataSyncEndingYear: this.props.fstep.QuickbooksDataSyncEndingYear,
      SyncFrequency: this.props.fstep.SyncFrequency,
      companyId: 0,
      accountantId: 0,
      mpwAccountantEmail: this.state.mpwAccountantEmail,
      infusionsoftContactId: this.state.infusionsoftContactId,
      accountingMethod: this.state.accountingMethod,
      accountingSoftwareId: Number(this.state.accountingSoftwareId),
      payrollSoftwareId: Number(this.state.payrollSoftwareId),
      isActive: true,
    };

    console.log(
      "🚀 ~ file: CompanyAccountsForm.js:76 ~ CompanyAccountsForm ~ data:",
      data
    );
    this.props.handleSave(false);
    this.props.insertCompanies(data);
  };
//   handleBeginningYearChange = (e) => {
//     console.log("e", e.target.value);
//     this.setState({ QuickbooksDataSyncBeginningYear: e.target.value });
//   };

  handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { visible, accountingSoft, payrollSoft } = this.props;
    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >
        <form
          method="post"
          onSubmit={this.handleSubmit}
          autoComplete="off"
          style={{ padding: 10 }}
        >
          <DialogTitle>General Ledger Review</DialogTitle>
          <DialogContent>
            <fieldset>
              <Grid container spacing={24}>
                <Grid item sm={4} style={{ paddingTop: 35 }}>
                  <Select
                    name="accountingSoftwareId"
                    native
                    fullWidth
                    value={this.state.accountingSoftwareId}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: "accountingSoftwareId",
                      id: "account-native-simple",
                    }}
                    style={{ paddingTop: "10px" }}
                  >
                    <option value={""} disabled>
                      Select Accouting Software
                    </option>
                    {accountingSoft.length &&
                      accountingSoft.map((value, index) => (
                        <option value={value.id} key={index}>
                          {value.name}
                        </option>
                      ))}
                  </Select>
                </Grid>
                <Grid item sm={4} style={{ paddingTop: 35 }}>
                  <Select
                    name="payrollSoftwareId"
                    native
                    fullWidth
                    value={this.state.payrollSoftwareId}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: "payrollSoftwareId",
                      id: "account-native-simple",
                    }}
                    style={{ paddingTop: "10px" }}
                  >
                    <option value={""} disabled>
                      Select Payroll Software
                    </option>
                    {payrollSoft.length &&
                      payrollSoft.map((value, index) => (
                        <option value={value.id} key={index}>
                          {value.name}
                        </option>
                      ))}
                  </Select>
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    name="mpwAccountantEmail"
                    required={true}
                    label={"Accounting Team Basecamp Email"}
                    type="email"
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.mpwAccountantEmail}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={24}>
                <Grid item sm={4}>
                  <TextField
                    name="infusionsoftContactId"
                    label={"Infusionsoft Contact ID"}
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.infusionsoftContactId}
                    margin="normal"
                  />
                </Grid>
                <Grid item sm={4} style={{ paddingTop: 35 }}>
                  <Select
                    name="accountingMethod"
                    native
                    fullWidth
                    value={this.state.accountingMethod}
                    onChange={this.handleFormChange}
                    required={true}
                    inputProps={{
                      name: "accountingMethod",
                      id: "account-native-simple",
                    }}
                    style={{ paddingTop: "10px" }}
                  >
                    <option value={""} disabled>
                      Select Accouting Method
                    </option>
                    {/* <option value={"C"}>Cash</option> */}
                    <option value={"A"}>Accrual</option>
                  </Select>
                </Grid>
                <Grid item sm={4} style={{ paddingTop: 35 }}>
                  {/* <Select
                    name="beginingYear"
                    native
                    fullWidth
                    value={this.state.QuickbooksDataSyncBeginningYear}
                    style={{ paddingTop: "10px" }}
                    onChange={(e) => this.handleBeginningYearChange(e)}
                  >
                    {" "}
                    <option value={null} disabled selected>
                      Select Beginning Year
                    </option>
                    {this.years.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select> */}
                </Grid>
              </Grid>
            </fieldset>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleBack} color="primary">
              Back
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button onClick={this.handleCancel} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      insertCompanies,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyAccountsForm);

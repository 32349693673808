import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '../../../../components/CircularProgress';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetBalanceSheetData, UpdateCustomField, ArchievedBalanceSheet, UpdateArchivedCustomField, GetEditArchivedData, GetLastQuarterGLReviewedDate } from "../../../../appRedux/actions/BalanceSheetAction";
import { GetLastQuarterEndDate, Getsum, LinkType } from "../../../common/commonServices";
// import { stat } from 'fs';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import BalanceSheetNodeComponent from "./BalanceSheetNode";
// import { da } from 'date-fns/locale';
import { toast } from 'react-toastify';
import ArchivedHistoryComponent from "./ArcvivedHistory";
import queryString from 'query-string';
import { AddQuestion } from "../../../../appRedux/actions/QuestionCommentsAction";

class BalanceSheetAccountReviewComponent extends Component {


    constructor(props) {
        super(props)
        this.eDateRef = React.createRef();

        let sDate = new Date(new Date().getFullYear(), 0, 1);
        if (sDate.getMonth() <= 2) {
            sDate.setFullYear(sDate.getFullYear() - 1, 0, 1);
        }
        this.state = {
            loading: false,
            companyId: 0,
            sDate: sDate,
            AsOnDate: null,
            ACMethod: "Accrual",
            lst_data: [],
            otherData: [],
            archiveList: [],
            ShowArchivedHistory: false,
            isRequestProcessing: false,
            refArchivedData: this.props.location.state ? this.props.location.state : null,
            editArchivedType: "",
            editReportDate: new Date(),
            IsCompared: false,
            isGLReviewCompleted: false,
            question: '',
            questionID: 0,
            questionData: null,
            // QuesRow: [],
        }
    }


    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            control = this.eDateRef;
            if (control.current != null) {
                control.current.setFocus();
            }
        }
    }

    componentDidMount() {

        let user = JSON.parse(localStorage.getItem('userData'));
        let companyId = user.companyId;
        this.setState({
            companyId: companyId,
            loading: true
        }, () => {
            this.props.GetLastQuarterGLReviewedDate(companyId);
        });
    }

    GetFirstReportData = () => {
        let user = JSON.parse(localStorage.getItem('userData'));
        let companyId = user.companyId;
        let AcMethod = this.state.ACMethod;
        let asOn = this.state.AsOnDate;
        let isComparedLink = this.state.IsCompared;
        if (this.props.location.search != null && this.props.location.search != "") {
            let value = queryString.parse(this.props.location.search);
            companyId = parseInt(value.cid);
            AcMethod = value.ACMethod;
            asOn = new Date(value.asOf);
            let temp = value.IsCompared;
            if (temp != null && temp.toLowerCase() == "true") {
                isComparedLink = true;
            }

        }
        if (AcMethod == null || AcMethod == "") {
            AcMethod = this.state.ACMethod;
        }
        this.setState({
            companyId: companyId,
            loading: true,
            ACMethod: AcMethod,
            AsOnDate: asOn,
            IsCompared: isComparedLink
        }, () => {
            if (this.state.refArchivedData == null) {
                this.GetReportData(false);

            }
            else {
                this.GetEditArchivedData();
            }

        });
    }



    GetReportData = () => {
        let eDate = moment(this.state.AsOnDate);
        let sDate = new Date(this.state.AsOnDate.getFullYear() - 1, this.state.AsOnDate.getMonth(), this.state.AsOnDate.getDate());
        sDate.setDate(sDate.getDate() + 1);
        let param = "CompanyID=" + this.state.companyId
            + "&From=" + moment(sDate).format("MM/DD/YYYY")
            + "&To=" + eDate.format("MM/DD/YYYY")
            + "&AccountingMethod=" + this.state.ACMethod;

        this.props.GetBalanceSheetData(param);
    }

    componentWillReceiveProps(nextprops) {

        if (nextprops.BalanceSheetData !== undefined && nextprops.BalanceSheetData !== this.props.BalanceSheetData && this.state.refArchivedData == null) {
            if (nextprops.BalanceSheetData.transactionStatus === 0) {

                let items = [];
                let BLData = nextprops.BalanceSheetData.returnObject;
                if (BLData.reportData.Columns.Column.length >= 2) {
                    items = this.BindArray(items, BLData.reportData, 1, BLData.otherData);

                    // for(let k=0;k<items.length;k++)
                    // {
                    //     if(items[k].GLBalance==0)   
                    //     {   
                    //         items[k].GLBalance= this.GetChildSum(items,0,items[k]);
                    //     }
                    // }
                    if (items != null && items.length > 0) {
                        items = this.GroupingArray(items).filter(p => p.level === 1);
                    }

                    if (BLData.archiveList != null && BLData.archiveList.length > 0) {
                        let dt = BLData.archiveList[0];
                        let message = "Last Balance sheet report as on " + dt.stReportDate + " was archived on " + dt.stArchiveDate + ". Click on view for history.";
                        toast.success(message);
                    }
                    // var QuestionDataRow = BLData.questingListingViewModels.filter(QData => QData.accountId > 0);
                    this.setState({ lst_data: items, isGLReviewCompleted: BLData.isGLReviewCompleted, isRequestProcessing: false, loading: false, archiveList: BLData.archiveList, ShowArchivedHistory: false });
                }
                else {
                    this.setState({ loading: false, isRequestProcessing: false, lst_data: [] }, () => { toast.error(`Data not found in QuickBook`); });
                }

            }
            else {
                this.setState({ loading: false, isRequestProcessing: false }, () => { toast.error(`${nextprops.BalanceSheetData.resultMsg}`); });
            }
        }

        if (nextprops.AddComman !== undefined && this.props.AddComman !== nextprops.AddComman) {
            if (nextprops.AddComman.transactionStatus === 0) {
                // toast.success("Record saved successfully.");                
                // this.setState({loading:false},() => { this.props.Reload();});

                this.setState({ loading: false, isRequestProcessing: true, questionData: null }, () => {
                    toast.success("Record saved successfully.");
                    if (this.state.refArchivedData == null) {
                        this.GetReportData();
                    }
                    else {
                        this.GetEditArchivedData();
                    }

                });
            }
            else {
                this.setState({ loading: false, isRequestProcessing: false }, () => { toast.error(`${nextprops.AddComman.resultMsg}`); });
            }
        }

        // if (nextprops.QuestionData !== undefined && this.props.QuestionData !== nextprops.QuestionData) {

        //     if (nextprops.QuestionData.transactionStatus === 0) {

        //         
        //         var QuestionDataRow =  nextprops.QuestionData.returnObject.filter(QData => QData.accountId > 0);
        //         this.setState({ loading: false, QuesRow: QuestionDataRow,isRequestProcessing: false });
        //     }
        //     else {
        //         this.setState({ loading: false,QuesRow:[],isRequestProcessing: false }, () => { toast.error(`${nextprops.QuestionData.resultMsg}`); });
        //     }
        // } 


        if (nextprops.GLReviewLastQueterDate !== undefined && this.props.GLReviewLastQueterDate !== nextprops.GLReviewLastQueterDate) {

            if (nextprops.GLReviewLastQueterDate.transactionStatus === 0) {


                var Data = nextprops.GLReviewLastQueterDate.returnObject;
                var GLReviewLastQueterDate = Data != null ? new Date(Data) : GetLastQuarterEndDate();

                this.setState({
                    AsOnDate: GLReviewLastQueterDate
                }, () => {
                    this.GetFirstReportData();
                });
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextprops.GLReviewLastQueterDate.resultMsg}`); });
            }
        }


        if (nextprops.UpdateData !== undefined && this.props.UpdateData !== nextprops.UpdateData) {
            if (nextprops.UpdateData.transactionStatus === 0) {

                if (this.state.questionData != null) {

                    //if(this.state.questionData.question != null && this.state.questionData.question != ''){
                    this.props.AddQuestion(this.state.questionData);
                    // } else {
                    //     this.setState({ loading: false, isRequestProcessing: true,questionData:null }, () => {
                    //         toast.success("Record saved successfully.");  
                    //         if (this.state.refArchivedData == null) {
                    //             this.GetReportData();
                    //         }
                    //         else {
                    //             this.GetEditArchivedData();
                    //         }

                    //     });
                    // }
                } else {
                    this.setState({ loading: false, isRequestProcessing: true, questionData: null }, () => {
                        toast.success("Record saved successfully.");
                        if (this.state.refArchivedData == null) {
                            this.GetReportData();
                        }
                        else {
                            this.GetEditArchivedData();
                        }

                    });
                }
            }
            else {
                this.setState({ loading: false, isRequestProcessing: false }, () => { toast.error(`${nextprops.UpdateData.resultMsg}`); });
            }
        }

        if (nextprops.EditArchivedData !== undefined && nextprops.EditArchivedData !== this.props.EditArchivedData && this.state.refArchivedData != null) {

            if (nextprops.EditArchivedData.transactionStatus === 0) {
                let items = [];

                if (nextprops.EditArchivedData.returnObject.reportData.Columns.Column.length >= 2) {
                    items = this.BindArray(items, nextprops.EditArchivedData.returnObject.reportData, 1, nextprops.EditArchivedData.returnObject.otherData);
                    if (items != null && items.length > 0) {
                        items = this.GroupingArray(items).filter(p => p.level === 1);
                    }
                    var QuestionDataRow = nextprops.EditArchivedData.returnObject.questingListingViewModels.filter(QData => QData.accountId > 0);
                    this.setState({ lst_data: items, isRequestProcessing: false, loading: false, archiveList: nextprops.EditArchivedData.returnObject.archiveList, ShowArchivedHistory: false, editReportDate: new Date(nextprops.EditArchivedData.returnObject.reportDate), editArchivedType: nextprops.EditArchivedData.returnObject.accountingMethod });//
                }
                else {
                    this.setState({ loading: false, isRequestProcessing: false, lst_data: [] }, () => { toast.error(`Data not found in QuickBook`); });
                }
            }
            else {
                this.setState({ loading: false, isRequestProcessing: false }, () => { toast.error(`${nextprops.EditArchivedData.resultMsg}`); });
            }
        }
    }

    GroupingArray = (arrayObj) => {

        arrayObj.map((data, index) => {
            if (data.IsHeader === true) {
                data.Items = arrayObj.filter(k => k.GroupName === data.Account);
            }
        })

        return arrayObj;
    }



    BindArray = (existingArray, items, level, otherData, GroupName = null) => {

        let temp = null;
        items.Rows.Row.map((data, index) => {


            if (data.Header !== undefined && data.Header != null) {

                temp = {
                    "Account": data.Header.ColData[0].value,
                    "Balance": 0,
                    "Notes": "",
                    "Question": "",
                    "QuestionId": 0,
                    "ReconciledThrough": "",
                    "STRT": "",
                    "Type": "",
                    "level": level,
                    "IsHeader": true,
                    "IsSummary": false,
                    "IsExpandable": (data.type === "Section") ? true : false,
                    "GroupName": level === 1 ? "" : (GroupName == null ? data.Header.ColData[0].value : GroupName),
                    "Items": [],
                    "IsData": false,
                    "AccountId": 0,
                    "GLBalance": 0
                };

                if (data.Header.ColData[0].id != null && data.Header.ColData[0].id != "" && data.Header.ColData[0].id > 0) {
                    let mapObj = this.GetMappDetails(data.Header.ColData[0].id, otherData);
                    if (mapObj != null) {
                        if (data.Header.ColData[1].value != null && data.Header.ColData[1].value.trim() != "") {
                            temp.Balance = parseFloat(data.Header.ColData[1].value);
                        }
                        else { temp.Balance = 0; }
                        temp.GLBalance = mapObj.length > 0 ? mapObj[0].glBalance : 0;
                    }


                }
                existingArray.push(temp);
            }
            if ((data.type === "Section") && data.Rows !== undefined && data.Rows.Row != undefined) {
                this.BindArray(existingArray, data, (level + 1), otherData, data.Header.ColData[0].value);
            }

            if (data.type === "Data" && data.ColData != null && data.ColData.length > 0) {

                let mapObj = this.GetMappDetails(data.ColData[0].id, otherData);

                temp = {
                    "Account": data.ColData[0].value,
                    "Balance": data.ColData.length > 1 ? data.ColData[1].value : 0,
                    "Notes": mapObj.length > 0 ? mapObj[0].comments : "",
                    "Question": mapObj.length > 0 ? mapObj[0].question : "",
                    "QuestionId": mapObj.length > 0 ? mapObj[0].questionId : 0,
                    "ReconciledThrough": mapObj.length > 0 ? mapObj[0].reconciledThrough : "",
                    "STRT": mapObj.length > 0 && mapObj[0].reconciledThrough != null ? moment(new Date(mapObj[0].reconciledThrough)).format("MM/DD/YYYY") : "",
                    "Type": mapObj.length > 0 ? mapObj[0].accountType : "",
                    "level": level,
                    "IsExpandable": (data.type === "Section") ? true : false,
                    "IsHeader": false,
                    "IsSummary": false,
                    "GroupName": GroupName,
                    "Items": [],
                    "IsData": true,
                    "AccountId": mapObj.length > 0 ? mapObj[0].accountID : 0,
                    "GLBalance": mapObj.length > 0 ? mapObj[0].glBalance : 0
                };

                if (temp.Account === "Retained Earnings" || temp.Account === "Net Revenue" || temp.Account === "Net Income") {
                    temp.GLBalance = temp.Balance;
                }
                existingArray.push(temp);
            }

            if (data.Summary != null && data.Summary !== undefined) {

                temp = {
                    "Account": data.Summary.ColData[0].value,
                    "Balance": data.Summary.ColData.length > 1 ? data.Summary.ColData[1].value : 0,
                    "Notes": "",
                    "Question": "",
                    "QuestionId": 0,
                    "ReconciledThrough": "",
                    "STRT": "",
                    "Type": "",
                    "level": level,
                    "IsExpandable": false,
                    "IsHeader": false,
                    "IsSummary": true,
                    "GroupName": level === 1 ? "" : (GroupName == null ? data.Header.ColData[0].value : GroupName),
                    "Items": [],
                    "IsData": false,
                    "AccountId": 0,
                    "GLBalance": 0
                };

                temp.GLBalance = this.GetTotal(data.Rows, 0, otherData);
                let result = this.HasChild(data.Rows);
                if (!result) {

                    if (data.Header.ColData[0].id != null && data.Header.ColData[0].id != "" && data.Header.ColData[0].id > 0) {
                        let mapObj = this.GetMappDetails(data.Header.ColData[0].id, otherData);
                        if (mapObj != null) {
                            temp.GLBalance += + mapObj.length > 0 ? mapObj[0].glBalance : 0;
                        }
                    }
                }

                existingArray.push(temp);
            }
        });

        return existingArray;
    }

    HasChild = (items) => {
        let result = false;
        if (items != null && items.Row != null && items.Row.length > 0) {
            items.Row.map((data, index) => {
                if (data.Header !== undefined && data.Header != null && data.Header.ColData[0].id != null && data.Header.ColData[0].id != "" && data.Header.ColData[0].id > 0 &&
                    (data.type === "Section") && data.Rows !== undefined && data.Rows.Row != undefined) {

                    result = true;
                    return result;
                }

            });
        }

        return result;
    }

    GetTotal = (items, amount, otherData) => {

        if (items != null && items.Row != null && items.Row.length > 0) {
            items.Row.map((data, index) => {


                if (data.Header !== undefined && data.Header != null && data.Header.ColData[0].id != null && data.Header.ColData[0].id != "" && data.Header.ColData[0].id > 0) {

                    let mapObj = this.GetMappDetails(data.Header.ColData[0].id, otherData);
                    try {
                        if (mapObj != null && mapObj.length > 0) {
                            amount = amount + mapObj[0].glBalance;
                        }
                    }
                    catch (err) { }
                }


                if (data.type === "Data" && data.ColData != null && data.ColData.length > 0) {

                    if (data.ColData[0].value === "Retained Earnings" || data.ColData[0].value === "Net Revenue" || data.ColData[0].value === "Net Income") {
                        if (data.ColData.length >= 2 && data.ColData[1].value != "") {
                            amount = amount + parseFloat(data.ColData[1].value);
                        }
                    }
                    else {
                        let mapObj = this.GetMappDetails(data.ColData[0].id, otherData);
                        try {
                            if (mapObj != null && mapObj.length > 0) {
                                amount = amount + mapObj[0].glBalance;
                            }
                        }
                        catch (err) { }
                    }
                }

                if ((data.type === "Section") && data.Rows !== undefined && data.Rows.Row != undefined) {

                    amount = amount + this.GetTotal(data.Rows, 0, otherData, false);

                }
            });
        }

        return amount;


    }

    handleFormChange = (e, target) => {
        if (!target) {
            this.setState({ ACMethod: e.target.value })
        }
        if (target === "AsOnDate") {
            this.setState({ AsOnDate: new Date(e) })
        }
    }

    ApplyDate = () => {
        this.setState({
            loading: true
        }, () => {
            this.GetReportData();
        });
    }

    ViewArchived = () => {
        this.props.history.push("archivedBL");
    }

    ArchivedData = () => {
        let body = {
            "companyID": this.state.companyId,
            "fromDate": moment(this.state.sDate).format("MM/DD/YYYY"),
            "toDate": moment(this.state.AsOnDate).format("MM/DD/YYYY"),
            "accountingMethod": this.state.ACMethod
        }
        this.setState({
            loading: true
        }, () => {
            this.props.ArchievedBalanceSheet(body)
        });
    }

    OnCOmpareChange = (e) => {

        this.setState({ IsCompared: e.target.checked, loading: true }, () => {
            setTimeout(() => {
                this.setState({ loading: false })
            }, 1200);
        })
    }


    GetEditArchivedData = () => {
        this.props.GetEditArchivedData(this.state.refArchivedData.ArchivedData.id)
    }

    GetMappDetails = (id, items) => {
        if (items != null && items.length > 0) {
            return items.filter(data => data.listID === id);
        }
        else {
            return null;
        }
    }


    UpdateFields = (data) => {

        let body = {};
        if (this.state.refArchivedData == null) {
            body = {
                "accountId": data.AccountId,
                "reconciledThrough": (data.ReconciledThrough == null || data.ReconciledThrough === "") ? null : moment(data.ReconciledThrough).format("MM/DD/YYYY"),
                "comments": data.Notes
            }
        }
        else {
            body = {
                "archiveID": this.state.refArchivedData.ArchivedData.id,
                "accountID": data.AccountId,
                "comments": data.Notes,
                "reconciledThrough": (data.ReconciledThrough == null || data.ReconciledThrough === "") ? null : moment(data.ReconciledThrough).format("MM/DD/YYYY")
            }
        }

        let QueBody = {
            "id": data.questionID != null && data.questionID != '' ? data.questionID : 0,
            "question": data.question,
            "status": false,
            "cpaReview": false,
            "companyID": this.state.companyId,
            "accountID": data.AccountId
        }

        // sprint 11 create a branch test
        this.setState({ loading: true, questionData: QueBody }, () => {
            if (this.state.refArchivedData == null) {
                this.props.UpdateCustomField(body);
            }
            else {
                this.props.UpdateArchivedCustomField(body);
            }


        });
    }

    CloseHistory = () => {
        this.setState({ ShowArchivedHistory: false });
    }

    render() {
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | Balance Sheet Review</h2>
                </div>
                {this.state.ShowArchivedHistory && <ArchivedHistoryComponent
                    data={this.state.archiveList}
                    CloseHistory={this.CloseHistory}>
                </ArchivedHistoryComponent>
                }
                <div className="padding-box app-wrapper padding-b0">
                    <div className="row">

                        {this.state.refArchivedData == null &&
                            <div className="col-12 col-md-4">
                                <div className="dashboard-box " style={{ flexWrap: "wrap" }}>
                                    <div className="datepicker-box">
                                        <div style={{ textAlign: "left" }}> <label className="pl-2">As of :</label>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.AsOnDate}
                                                    onChange={(e) => this.handleFormChange(e, 'AsOnDate')}
                                                    label="Loan Date"
                                                    id="date"
                                                    name="AsOn"
                                                    margin="normal"
                                                    maxDate={new Date()}
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: "left" }}>
                                            <label className="pl-4">A/C Method</label>
                                            <div className="date-box" style={{ padding: "0px 20px" }}>
                                                <Select
                                                    name="accountingMethod"
                                                    required={true}
                                                    native
                                                    value={this.state.ACMethod}
                                                    onChange={this.handleFormChange}
                                                >
                                                    <option value={""} disabled>Select Accouting Method</option>
                                                    {/* <option value={"Cash"}>Cash</option> */}
                                                    <option value={"Accrual"}>Accrual</option>
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="genera-apply-btn" style={{ marginTop: "10px", textAlign: "left" }}>
                                        <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.refArchivedData != null &&
                            <div className="col-12 col-md-4">
                                <div className="dashboard-box " style={{ flexWrap: "wrap" }}>
                                    <div className="datepicker-box">
                                        <div style={{ textAlign: "left" }}> <label className="pl-2">As On :</label>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.editReportDate}
                                                    label="Loan Date"
                                                    id="date"
                                                    name="AsOn"
                                                    margin="normal"
                                                    disabled
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: "left" }}>
                                            <label className="pl-4">A/C Method</label>
                                            <div className="date-box" style={{ padding: "0px 20px" }}>
                                                <Select
                                                    name="accountingMethod"
                                                    native
                                                    value={this.state.editArchivedType}
                                                    disabled
                                                >
                                                    <option value={""} disabled>Select Accouting Method</option>

                                                    {/* {this.state.editArchivedType == "Cash" ?
                                                        <option value={"Cash"} selected="selected"> Cash</option>
                                                        :
                                                        <option value={"Cash"}> Cash</option>
                                                    } */}

                                                    {this.state.editArchivedType == "Accrual" ?
                                                        <option value={"Accrual"} selected="selected">Accrual</option>
                                                        :
                                                        <option value={"Accrual"}>Accrual</option>
                                                    }


                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-12 col-md-8">
                            <div className="btncheck_compare">
                                <div className="singlecheckbox">
                                    {this.state.refArchivedData == null && this.state.loading === false &&
                                        <div className="checkbox pointer" >
                                            <div className="chiller_cb">
                                                <input id="TFB" type="checkbox" checked={this.state.IsCompared} onChange={(e) => this.OnCOmpareChange(e)} />
                                                <label for="TFB">
                                                    <p className="checkbox-title">Compare</p>

                                                </label>
                                                <span></span>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="float-right">
                                    {this.state.refArchivedData == null &&
                                        <React.Fragment>
                                            {
                                            // this.state.isGLReviewCompleted && 
                                            <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ArchivedData()} >Archive</Button>}
                                        </React.Fragment>


                                    } 
                                    <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn view-btn" onClick={() => this.ViewArchived()} >View</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loading ?
                    <CircularProgress></CircularProgress>
                    :
                    <React.Fragment>
                        <div className="padding-box app-wrapper account-reconcilliation balancereview-page" style={{ paddingTop: "0px" }}>
                            <div className="table-responsive-material">
                                <table className="table balance-sheet-page" >
                                    <thead className="panel-bg white_header_text">
                                        {/* <tr > */}
                                            {/* <th  className="td-width">no.</th> */}
                                            {this.state.IsCompared ?
                                                <React.Fragment>
                                                    <th className="width-300" >Account No.</th>
                                                    <th className="td-width balance-td-width" >Compliance Balance</th>
                                                    <th className="td-width balance-td-width" >Quickbook Balance</th>
                                                    <th className="td-width  text-center balance-td-width"> Difference </th>
                                                    <th>Notes</th>
                                                    <th>Questions and Comments</th>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <th className="width-300" >Account No.</th>
                                                    <th className="td-width type-td-width" >Type</th>
                                                    <th className="td-width text-center balance-td-width"> Balance </th>
                                                    <th className="td-width balance-td-width" >Reconciled Through</th>
                                                    <th>Notes</th>
                                                    <th>Questions and Comments</th>
                                                </React.Fragment>
                                            }

                                        {/* </tr> */}
                                    </thead>
                                    <tbody>
                                        {!this.state.isRequestProcessing &&
                                            <BalanceSheetNodeComponent
                                                data={this.state.lst_data}
                                                // quedata={this.state.QuesRow}
                                                level={1}
                                                UpdateFields={this.UpdateFields}
                                                IsCompared={this.state.IsCompared}
                                            >
                                            </BalanceSheetNodeComponent>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        BalanceSheetData: state.BalanceSheetData.BalanceSheetData,
        UpdateData: state.BalanceSheetData.UpdateData,
        EditArchivedData: state.BalanceSheetData.EditArchivedData,
        AddComman: state.QuestionCommentsData.AddComman,
        GLReviewLastQueterDate: state.BalanceSheetData.GLReviewLastQueterDate,
        // QuestionData: state.QuestionCommentsData.QuestionData,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetBalanceSheetData,
        UpdateCustomField,
        ArchievedBalanceSheet,
        GetEditArchivedData,
        UpdateArchivedCustomField,
        AddQuestion,
        GetLastQuarterGLReviewedDate
        //GetQuestionList
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheetAccountReviewComponent);
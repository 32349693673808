import React from "react";
import { connect } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import { bindActionCreators } from "redux";
import { updateCompanies } from '../appRedux/actions/companiesAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import RSelect from 'react-select';

const theme = {
  'border-color': 'hsl(0, 0%, 58%)',
  'border-bottom': 'solid !important',
  'border-width': 1
}

class EditCompnayForm extends React.Component {

  constructor() {
    super()
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear + 77; year >= currentYear - 50; year--) {
      years.push(year);
    }
    this.years = years
    this.state = {
      country: '',
      name: '',
      state: '',
      city: '',
      postalCode: '',
      eiNnumber: '',
      address: '',
      companyId: '',
      quickbookOnline: '',
      accountantId: '',
      mpwAccountantEmail: '',
      infusionsoftContactId: '',
      accountingMethod: "",
      selectedState: '',
      isReligeousOrganization: false,
      isFullServiceBookkeeping: false,
      QuickbooksDataSyncBeginningYear: null,
      QuickbooksDataSyncEndingYear: null,
      SyncFrequency: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log("🚀 ~ file: EditCompanyForm.js:56 ~ EditCompnayForm ~ componentWillReceiveProps ~ nextProps:", nextProps)
    if (this.props.selectedItem !== nextProps.selectedItem) {
      this.setState({
        "country": nextProps.selectedItem.country,
        "name": nextProps.selectedItem.name,
        "state": nextProps.selectedItem.state,
        "city": nextProps.selectedItem.city,
        "postalCode": nextProps.selectedItem.postalCode,
        "eiNnumber": nextProps.selectedItem.eiNnumber,
        "address": nextProps.selectedItem.address,
        "companyId": nextProps.selectedItem.companyId,
        "accountantId": nextProps.selectedItem.accountantId,
        "mpwAccountantEmail": nextProps.selectedItem.mpwAccountantEmail,
        "infusionsoftContactId": nextProps.selectedItem.infusionsoftContactId,
        accountingSoftwareId: nextProps.selectedItem.accountingSoftwareId || "",
        payrollSoftwareId: nextProps.selectedItem.payrollSoftwareId || "",
        accountingMethod: nextProps.selectedItem.accountingMethod || "",
        isReligeousOrganization: nextProps.selectedItem.isReligeousOrganization,
        isFullServiceBookkeeping: nextProps.selectedItem.isFullServiceBookkeeping,
        QuickbooksDataSyncBeginningYear: nextProps.selectedItem.quickbooksDataSyncBeginningYear ? nextProps.selectedItem.quickbooksDataSyncBeginningYear : null,
        QuickbooksDataSyncEndingYear: nextProps.selectedItem.quickbooksDataSyncEndingYear ? nextProps.selectedItem.quickbooksDataSyncEndingYear : null,
        SyncFrequency: nextProps.selectedItem.syncFrequency || ""
      });
    }

  }



  handleCancel = () => {
    this.props.handleCancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { QuickbooksDataSyncBeginningYear, QuickbooksDataSyncEndingYear } = this.state;

    if (QuickbooksDataSyncBeginningYear > QuickbooksDataSyncEndingYear) {
      this.setState({ beginningYearError: true });
      return; // Prevent submission if validation fails
    }

    this.setState({ beginningYearError: false });

    let data = {
      "name": this.state.name,
      "state": this.state.state,
      "city": this.state.city,
      "postalCode": this.state.postalCode,
      "eiNnumber": this.state.eiNnumber,
      "address": this.state.address,
      "companyId": this.state.companyId,
      "accountingMethod": this.state.accountingMethod,
      "accountingSoftwareId": this.state.accountingSoftwareId,
      "payrollSoftwareId": this.state.payrollSoftwareId,
      "mpwAccountantEmail": this.state.mpwAccountantEmail,
      "infusionsoftContactId": this.state.infusionsoftContactId,
      "isReligeousOrganization": this.state.isReligeousOrganization,
      "isFullServiceBookkeeping": this.state.isFullServiceBookkeeping,
      "QuickbooksDataSyncBeginningYear": QuickbooksDataSyncBeginningYear ? QuickbooksDataSyncBeginningYear : null,
      "QuickbooksDataSyncEndingYear": QuickbooksDataSyncEndingYear ? QuickbooksDataSyncEndingYear : null,
      "SyncFrequency": this.state.SyncFrequency
    }

    // if (this.state.QuickbooksDataSyncEndingYear) {
    //   sessionStorage.setItem('endingYear', JSON.stringify(this.state.QuickbooksDataSyncEndingYear));
    // }

    this.props.nextForm(data);
  }
  handleCompChange = (selectedState) => {
    this.setState({ state: selectedState, err: false });
  }
  handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleBeginningYearChange = (e) => {
    const selectedYear = e.target.value;
    const { QuickbooksDataSyncEndingYear } = this.state;

    if (QuickbooksDataSyncEndingYear && selectedYear > QuickbooksDataSyncEndingYear) {
      this.setState({
        beginningYearError: true,
        QuickbooksDataSyncBeginningYear: null,
      });
    } else {
      this.setState({
        QuickbooksDataSyncBeginningYear: selectedYear,
        beginningYearError: false,
      });
    }
  };

  handleEndingYearChange = (e) => {
    const selectedYear = e.target.value;
    const { QuickbooksDataSyncBeginningYear } = this.state;

    if (QuickbooksDataSyncBeginningYear && QuickbooksDataSyncBeginningYear > selectedYear) {
      this.setState({
        beginningYearError: true,
        QuickbooksDataSyncEndingYear: selectedYear,
      });
    } else {
      this.setState({
        QuickbooksDataSyncEndingYear: selectedYear,
        beginningYearError: false,
      });
    }
  };
  
  ShowWages = () => {
    this.props.ShowWages();
  }

  handleFormChecked = (e) => {
    this.setState({ isReligeousOrganization: e.target.checked });
  }
  handleFullServiceBookkeepingChecked = (e) => {
    this.setState({ isFullServiceBookkeeping: e.target.checked });
  }
  render() {
    const { visible, selectedItem, states, accountingSoft, payrollSoft } = this.props;
    const options = states.map(data => {
      return {
        value: data.stateVchID,
        label: data.vchStateLongName
      }
    });
    return (
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={visible}
        onClose={this.handleCancel}
      >
        <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }}>
          <DialogTitle>Edit Company</DialogTitle>
          <DialogContent>
            <fieldset>
              <Grid container spacing={24}>
                <Grid item sm={4}>
                  <TextField
                    name="name"
                    required={true}
                    label={"Company Name"}
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.name}
                    margin="normal"
                  />
                </Grid>
                <Grid item sm={4} >
                  <TextField
                    name="address"
                    label="Legal Address"
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.address}
                    margin="normal"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    name="city"
                    label={"City"}
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.city}
                    margin="normal"
                  />
                </Grid>


              </Grid>
              <Grid container spacing={24}  >
                <Grid item sm={4} style={{ paddingTop: 15 }} >
                  <InputLabel style={{ paddingBottom: 5 }} htmlFor="teax-native-simple">State</InputLabel>
                  {/*<RSelect
                                 value={this.state.state}
                                 onChange={this.handleCompChange}
                                 options={options}
                                 isClearable
                                 placeholder="Select State.."
                                 theme={theme}
                                 isMulti={false}
                               />*/}
                  <Select
                    name="state"
                    native
                    fullWidth
                    value={this.state.state}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: 'state',
                      id: 'account-native-simple',
                    }}
                    style={{ 'paddingTop': '10px' }}
                  >
                    <option value={""}>Select State</option>
                    {states.length && states.map((value, index) => (
                      <option value={value.stateVchID} key={index}>{value.vchStateLongName}</option>
                    ))}
                  </Select>
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    name="postalCode"
                    label={"Zip Code"}
                    fullWidth
                    onChange={(e) => { this.setState({ postalCode: e.target.value.substring(0, 5) }) }}
                    value={this.state.postalCode}
                    margin="normal"
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    name="eiNnumber"
                    label={"EIN Nnumber"}
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.eiNnumber}
                    margin="normal"
                  />
                </Grid>

              </Grid>

              <Grid container spacing={24}  >
                <Grid item sm={4}>
                  <InputLabel htmlFor={""}>Accounting Software</InputLabel>
                  <Select
                    name="accountingSoftwareId"
                    native
                    fullWidth
                    value={this.state.accountingSoftwareId}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: 'accountingSoftwareId',
                      id: 'account-native-simple',
                    }}
                    style={{ 'paddingTop': '10px' }}
                  >
                    <option value={""} disabled>Select Accouting Software</option>
                    {accountingSoft.length && accountingSoft.map((value, index) => (
                      <option value={value.id} key={index}>{value.name}</option>
                    ))}
                  </Select>
                </Grid>

                <Grid item sm={4}>
                  <InputLabel htmlFor={""}>Payroll Software</InputLabel>
                  <Select
                    name="payrollSoftwareId"
                    native
                    fullWidth
                    value={this.state.payrollSoftwareId}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: 'payrollSoftwareId',
                      id: 'account-native-simple',
                    }}
                    style={{ 'paddingTop': '10px' }}
                  >
                    <option value={""} disabled>Select Payroll Software</option>
                    {payrollSoft.length && payrollSoft.map((value, index) => (
                      <option value={value.id} key={index}>{value.name}</option>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    name="mpwAccountantEmail"
                    required={true}
                    label={"Accounting Team Basecamp Email"}
                    type="email"
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.mpwAccountantEmail}
                    margin="normal"
                  />
                </Grid>


              </Grid>
              <Grid container spacing={24}  >
                <Grid item sm={4} >
                  <TextField
                    name="infusionsoftContactId"
                    label={"Infusionsoft Contact ID"}
                    fullWidth
                    onChange={this.handleFormChange}
                    defaultValue={this.state.infusionsoftContactId}
                    margin="normal"
                  />
                </Grid>

                <Grid item sm={4}>
                  <InputLabel htmlFor={""}>Accounting Method</InputLabel>
                  <Select
                    name="accountingMethod"
                    native
                    fullWidth
                    required={true}
                    value={this.state.accountingMethod}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: 'accountingMethod',
                      id: 'account-native-simple',
                    }}
                    style={{ 'paddingTop': '10px' }}
                  >
                    <option value={""} disabled>Select Accouting Method</option>
                    {/* <option value={"C"}>Cash</option> */}
                    <option value={"A"}>Accrual</option>

                  </Select>
                </Grid>

                <Grid item sm={4}>
                  <InputLabel htmlFor={""}>Beginning Year</InputLabel>
                  <Select
                    name="beginningYear"
                    native
                    fullWidth
                    value={this.state.QuickbooksDataSyncBeginningYear}
                    style={{ 'paddingTop': '10px' }}
                    onChange={(e) => this.handleBeginningYearChange(e)}
                  >
                    <option value={null} disabled selected={this.state.QuickbooksDataSyncBeginningYear ? `` : true}>
                      Select Beginning Year
                    </option>
                    {this.years.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                  {this.state.beginningYearError && (
                    <p style={{ color: 'red' }}>Beginning Year cannot be greater than Ending Year</p>
                  )}
                </Grid>

                <Grid item sm={4}>
                  <InputLabel htmlFor={""}>Ending Year</InputLabel>
                  <Select
                    name="endingYear"
                    native
                    fullWidth
                    value={this.state.QuickbooksDataSyncEndingYear}
                    style={{ 'paddingTop': '10px' }}
                    onChange={(e) => this.handleEndingYearChange(e)}
                  > <option value={null} disabled selected={this.state.QuickbooksDataSyncEndingYear ? `` : true}>Select Ending Year</option>
                    {this.years.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </Grid>

                <Grid item sm={4}>
                  <InputLabel htmlFor={""}>Sync Frequency</InputLabel>
                  <Select
                    name="SyncFrequency"
                    native
                    fullWidth
                    required={true}
                    value={this.state.SyncFrequency}
                    onChange={this.handleFormChange}
                    inputProps={{
                      name: 'SyncFrequency',
                    }}
                    style={{ 'paddingTop': '10px' }}
                  >
                    <option value={"Daily"}>Daily</option>
                    <option value={"Weekly"}>Weekly</option>
                    <option value={"Disabled"}>Disabled</option>
                  </Select>
                </Grid>
              </Grid>
              <Grid container spacing={24}  >
                <Grid item sm={4}>

                  <Checkbox
                    name="transctionTypes"
                    value={this.state.isReligeousOrganization}
                    checked={this.state.isReligeousOrganization}
                    onChange={(e) => this.handleFormChecked(e)}
                  />
                  <InputLabel htmlFor={""}>Religious Organization</InputLabel>
                </Grid>
                <Grid item sm={4}>

                  <Checkbox
                    name="fullServiceBookkeeping"
                    value={this.state.isFullServiceBookkeeping}
                    checked={this.state.isFullServiceBookkeeping}
                    onChange={(e) => this.handleFullServiceBookkeepingChecked(e)}
                  />
                  <InputLabel htmlFor={""}>Full Service Bookkeeping</InputLabel>
                </Grid>
              </Grid>
            </fieldset>

          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Next
            </Button>

            <Button type="button" onClick={() => this.ShowWages()} color="primary">
              Payroll Settings
            </Button>

            <Button onClick={this.handleCancel} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}



const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateCompanies
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompnayForm);




import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@material-ui/core/TextField';
import Textarea from 'react-expanding-textarea';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// import Select from '@material-ui/core/Select';
import RSelect from 'react-select';
import { toast } from "react-toastify";
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { quarterDates, firstTimeGetPrevQuater, GetLastQuarterEndDate, TransactionsType, numberWithCommas, makeLinkForQuickbooks, ModuleType, ReportType, GetSyncDateFormat, LinkType } from '../../../common/commonServices';
import QuaterDatePickerModel from '../../../common/quaterDatePickerModel';
import { GetLedgerDetail_New, LedgerLastLog, LedgerUpdate, MarkReview, StartReview, UpdateBatchNewAccount } from '../../../../appRedux/actions/LedgerAction';
import { Accounts } from '../../../../appRedux/actions/accountAction';
import CircularProgress from "../../../../components/CircularProgress";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonComments from './../../../common/Comments';
import ReviewPopup from "./../../../common/ReviewPopUp";
import LogHistory from './../../../common/logHistoryModel';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { getCompnay } from '../../../../appRedux/actions/companiesAction'
import { GetGeneralReportNotes, InsertUpdateGeneralReportNotes } from '../../../../appRedux/actions/ReconcillitionAction'
import { companyDetail } from '../../../../appRedux/services/companiesService';
// import TableScrollbar from 'react-table-scrollbar';


const FontTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12
    },
}))(Tooltip);

class NewLedgerPage extends Component {

    constructor(props) {
        super(props);
        this.sDateRef = React.createRef();
        this.eDateRef = React.createRef();

        this.state = {
            lastSyncObj: {},
            loading: false,
            LedgerDetailLoading: false,
            companyId: 0,
            lst_GL: [],
            accounts: [],
            quater: null,
            TFB: false,
            VENDOR1099: false,
            NBD: false,
            TCI: false,
            AC: false,
            IN: false,
            PC: false,
            CPA: false,
            APA: true,
            pcTransactionCheck: false,
            BankCCTrans: false,
            expandall: true,
            collapsall: false,
            editIndex: -1,
            editIndex1: -1,
            editData: null,
            sortField: 'date',
            sortFormat: "D",
            selectedDateRange: null,
            searchDate: '',
            searchTranType: { value: 'All', label: 'All' },
            searchNum: '',
            searchName: '',
            searchMemo: '',
            searchAmt: '',
            searchNewAccount: '',
            isOpenDatepicker: false,
            eDate: null,
            sDate: null,
            ShowComment: false,
            ColorCode: "#46b29d",
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            ShowHistory: false,
            HistoryRecordId: 0,
            LastUpdated: "",
            isReviewCompleted: false,
            syncFrequency: "",
            endingYear: "",
            SearchDateOBJ: {
                from: null,
                to: null
            },
            ShowReview: false,
            ReviewList: [],
            TransType: [],
            DisplayAccounts: [],
            selectedAccount: { value: 0, label: "Select" },
            IsStartRewViewProcessing: false,
            IsMarkReviewProcessing: false,
            selectedBatchAccountId: { value: 0, label: "Select" },
            SelectALL: false,
            SelectedRecords: [],
            Notes: "",
            prevNotes: "",
        }

        this.glDataFetched = false;
    }

    adjustDatesToEndingYear(sDate, eDate, endingYear) {
        const endingYearNumber = parseInt(endingYear, 10);
        const currentYear = new Date().getFullYear();

        if (sDate.getFullYear() > endingYearNumber) {
            sDate.setFullYear(endingYearNumber);
        }

        if (endingYearNumber < currentYear) {
            eDate = new Date(endingYearNumber, 11, 31);
        } else {
            if (eDate.getFullYear() > endingYearNumber) {
                eDate.setFullYear(endingYearNumber);
            }
        }

        return { sDate, eDate };
    }

    onPrevQuater = () => {
        if (!this.state.loading) {
            var date = new Date(this.state.quater.quarterStartDate);
            date.setDate(date.getDate() - 1);
            var quater = quarterDates(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
            this.setState({
                quater: quater,
                searchDate: '',
                selectedDateRange: { startDate: quater.quarterStartDate, endDate: quater.quarterEndDate, },
                loading: true
            }, () => {
                this.getGLData();
            });
        }
    }
    LoadComments = (e, item) => {
        e.stopPropagation();
        this.setState({ ShowComment: true, CommentReferenceId: item.id });
    }

    LoadHistory = (e, item) => {
        e.stopPropagation();
        this.setState({ HistoryRecordId: item.id, ShowHistory: true })
    }

    CloseHistory = () => {
        this.setState({ ShowHistory: false });
    }

    CloseComments = () => {
        this.setState({ ShowComment: false });
    }

    onNextQuater = () => {
        if (!this.state.loading) {
            var date = new Date(this.state.quater.quarterEndDate);
            date.setDate(date.getDate() + 1);
            var quater = quarterDates(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
            this.setState({
                quater: quater,
                searchDate: '',
                selectedDateRange: { startDate: quater.quarterStartDate, endDate: quater.quarterEndDate, },
                loading: true
            }, () => {
                this.getGLData();
            });
        }
    }

    onTFBChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ TFB: isChecked, loading: true }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onVendor1099Change = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ VENDOR1099: isChecked, loading: true, }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onNBDChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ NBD: isChecked, loading: true, }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onTCIChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ TCI: isChecked, loading: true, }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }

    onPCTChange = (isChecked) => {

        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ pcTransactionCheck: isChecked, loading: true, }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }

    }

    onAPAChange = (isChecked) => {

        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ APA: isChecked, loading: true, }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }

    }

    onACChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ AC: isChecked, loading: true }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onINChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ IN: isChecked, loading: true }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onCPAChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ CPA: isChecked, loading: true }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onPCChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ PC: isChecked, loading: true }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onBanckCCTranChange = (isChecked) => {
        if (!this.state.loading) {
            let obj = this.state.SearchDateOBJ;
            this.setState({ BankCCTrans: isChecked, loading: true }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }
    onExpandAllChange = (isChecked) => {
        const data = [...this.state.lst_GL];
        data.forEach(element => { element.isExpand = isChecked });
        this.setState({ expandall: true, collapsall: false, lst_GL: data });
    }
    onCollapseAllChange = (isChecked) => {
        const data = [...this.state.lst_GL];
        data.forEach(element => { element.isExpand = isChecked });
        this.setState({ collapsall: true, expandall: false, lst_GL: data });
    }

    onSortChange = (fieldName) => {
        if (this.state.sortField === fieldName) {
            let formate = this.state.sortFormat === 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate, loading: true }, () => {
                this.getGLData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
                this.getGLData();
            });
        }
    }

    onSearchDateFocus = () => {
        this.setState({ isOpenDatepicker: true });
    }

    onDatepickerCancel = (range) => {
        if (range != undefined) {
            var rangeDate = range ? `${moment(range.startdate).format("MM/DD/YYYY")} - ${moment(range.enddate).format("MM/DD/YYYY")}` : '';
            var startDate = range ? `${moment(range.startdate).format("MM/DD/YYYY")}` : this.state.quater.quarterStartDate;
            var endDate = range ? `${moment(range.enddate).format("MM/DD/YYYY")}` : this.state.quater.quarterEndDate;
            this.setState({
                searchDate: rangeDate,
                isOpenDatepicker: false,
                SearchDateOBJ: {
                    from: range.startdate,
                    to: range.enddate
                },
                loading: true
            }, () => {
                this.getGLData(false, range.startdate, range.enddate);
            });
        }
        else {
            this.setState({ isOpenDatepicker: false });
        }
    }

    onSearchBoxEnter = (e) => {
        if (e.key === 'Enter') {
            let obj = this.state.SearchDateOBJ;
            this.setState({ loading: true }, () => {
                this.getGLData(false, obj.from, obj.to);
            });
        }
    }

    searchTranTypeChange = (e) => {
        let obj = this.state.SearchDateOBJ;
        this.setState({ searchTranType: e, loading: true }, () => {
            this.getGLData(false, obj.from, obj.to);
        });
    }


    lastSyncDetails = (companyId) => {
        LedgerLastLog(companyId).then((response) => {
            if (response.data && response.data.length > 0) {
                this.setState({ lastSyncObj: response.data[0], LastUpdated: GetSyncDateFormat(response.data[0].logDate) }, () => {
                    //var date = new Date(this.state.lastSyncObj.LogDate);
                });
            }
        }).catch((err) => {

        });
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.getGLData(true);
            });
        }
    }

    onStartReview = () => {
        let data = {
            "companyID": this.state.companyId,
            "fromDate": moment(this.state.sDate).format("MM/DD/YYYY"),
            "toDate": moment(this.state.eDate).format("MM/DD/YYYY")
        };

        this.setState({ IsStartRewViewProcessing: true, loading: true }, () => {
            this.props.StartReview(data);
        });

    }

    onMarkReviewd = () => {
        let data = {
            "companyID": this.state.companyId,
            "fromDate": moment(this.state.sDate).format("MM/DD/YYYY"),
            "toDate": moment(this.state.eDate).format("MM/DD/YYYY")
        };

        this.setState({ IsMarkReviewProcessing: true, loading: true }, () => {
            this.props.MarkReview(data);
        });

    }

    getGLData = (isRefresh = false, searchfrom = null, SearchTo = null) => {
        let value = this.state;
        let start = searchfrom == null ? value.sDate : searchfrom;
        let end = SearchTo == null ? value.eDate : SearchTo;
        let body = {
            companyId: value.companyId,
            sortField: value.sortField,
            sortFormat: value.sortFormat,
            fromDate: moment(start).format("MM/DD/YYYY"),
            toDate: moment(end).format("MM/DD/YYYY"),
            isTaxableFringeBenefit: value.TFB,
            is1099Vendor: value.VENDOR1099,
            isNeedBetterDescription: value.NBD,
            isTransferCodedIncorrectly: value.TCI,
            hasAccountComment: value.AC,
            hasInternalNotes: value.IN,
            showBankCCTran: value.BankCCTrans,
            pcCheck: value.PC,
            cpaReview: value.CPA,
            date: '',
            tranType: value.searchTranType.value == 'All' ? '' : value.searchTranType.value,
            num: value.searchNum,
            name: value.searchName,
            memo: value.searchMemo,
            amount: value.searchAmt == '' ? 0 : value.searchAmt,
            newAct: value.searchNewAccount,
            needUpdatQBORecords: isRefresh,
            pcTransactionCheck: value.pcTransactionCheck,
            isAccountPayable: value.APA
        }
        this.setState({ LedgerDetailLoading: true }, () => {

            this.props.GetLedgerDetail_New(body);
        });
    }

    onRowExpandCollapseClick = (accountIndex, rowData) => {

        var dataOfGL = [...this.state.lst_GL];
        dataOfGL[accountIndex].isExpand = !dataOfGL[accountIndex].isExpand;
        if (this.state.editData && this.state.editIndex1 > -1 && this.state.editIndex > -1) {
            var dataOfGL = [...this.state.lst_GL];
            let editIndex1 = this.state.editIndex1;
            let editIndex = this.state.editIndex;
            let editData = this.state.editData;
            dataOfGL[editIndex1].data[editIndex].vendor1099 = editData.vendor1099;
            dataOfGL[editIndex1].data[editIndex].taxableFringeBenefit = editData.tfb;
            dataOfGL[editIndex1].data[editIndex].needsBetterDescription = editData.nbd;
            dataOfGL[editIndex1].data[editIndex].transferCodedIncorrectly = editData.tci;
            dataOfGL[editIndex1].data[editIndex].accountantComment = editData.ac;
            dataOfGL[editIndex1].data[editIndex].internalNotes = editData.in;
            dataOfGL[editIndex1].data[editIndex].newAccountID = editData.newAccId;
            dataOfGL[editIndex1].data[editIndex].newAccountName = editData.newAccName;
            dataOfGL[editIndex1].data[editIndex].cpaReview = editData.cpa;
            dataOfGL[editIndex1].data[editIndex].pcCheck = editData.pc;
            this.setState({
                lst_GL: dataOfGL, editIndex1: -1,
                editIndex: -1,
                editData: null
            });
        } else {
            this.setState({
                lst_GL: dataOfGL,
                editIndex1: -1,
                editIndex: -1,
                editData: null
            });
        }
    }

    onLinkClick = (e, accountIndex, rowIndex, rowData) => {
        e.stopPropagation()
        let origin = makeLinkForQuickbooks();
        window.open(origin + rowData.transactionLink);
    }

    onRowClick = (e, accountIndex, rowIndex, rowData) => {

        if (this.state.editData && this.state.editIndex1 > -1 && this.state.editIndex > -1) {
            var dataOfGL = [...this.state.lst_GL];
            let editIndex1 = this.state.editIndex1;
            let editIndex = this.state.editIndex;
            let editData = this.state.editData;
            dataOfGL[editIndex1].data[editIndex].vendor1099 = editData.vendor1099;
            dataOfGL[editIndex1].data[editIndex].taxableFringeBenefit = editData.tfb;
            dataOfGL[editIndex1].data[editIndex].needsBetterDescription = editData.nbd;
            dataOfGL[editIndex1].data[editIndex].transferCodedIncorrectly = editData.tci;
            dataOfGL[editIndex1].data[editIndex].accountantComment = editData.ac;
            dataOfGL[editIndex1].data[editIndex].internalNotes = editData.in;
            dataOfGL[editIndex1].data[editIndex].newAccountID = editData.newAccId;
            dataOfGL[editIndex1].data[editIndex].newAccountName = editData.newAccName;
            dataOfGL[editIndex1].data[editIndex].cpaReview = editData.cpa;
            dataOfGL[editIndex1].data[editIndex].pcCheck = editData.pc;
            this.setState({ lst_GL: dataOfGL });
        }
        let data = {
            tfb: rowData.taxableFringeBenefit,
            vendor1099: rowData.vendor1099,
            nbd: rowData.needsBetterDescription,
            tci: rowData.transferCodedIncorrectly,
            ac: rowData.accountantComment,
            in: rowData.internalNotes,
            newAccId: rowData.newAccountID,
            newAccName: rowData.newAccountName,
            cpa: rowData.cpaReview,
            pc: rowData.pcCheck,
        }
        this.setState({
            editIndex1: accountIndex,
            editIndex: rowIndex,
            editData: data,
            selectedAccount: { value: data.newAccId, label: data.newAccName }
        });
    }

    onRowUpdateClick = (accountIndex, rowIndex, rowData) => {

        let data = {
            id: rowData.id,
            transactionTypeId: rowData.transactionTypeId,
            transactionType: rowData.transactionType,
            accountId: rowData.accountId,
            newAccountId: Number(rowData.newAccountID == '' ? 0 : rowData.newAccountID),
            needsBetterDescription: rowData.needsBetterDescription,
            transferCodedIncorrectly: rowData.transferCodedIncorrectly,
            cpaReview: rowData.cpaReview,
            pcCheck: rowData.pcCheck,
            accountantComment: rowData.accountantComment,
            internalNotes: rowData.internalNotes,
            taxableFringeBenefit: rowData.taxableFringeBenefit,
            vendor1099: rowData.vendor1099,
            taxReporting: rowData.taxReporting,
            isArchived: rowData.isArchived || false,
            benefitTypeId: rowData.benefitTypeId || 0,
            employeeName: rowData.employeeName || '',
            clientComment: rowData.clientComment || '',
            accountChangeApprovedByClient: rowData.accountChangeApprovedByClient || false,
            clientReviewStatus: rowData.clientReviewStatus || false,
            accountantReviewStatus: rowData.accountantReviewStatus || false,
            descriptionNeedsEmployeeName: rowData.descriptionNeedsEmployeeName || false,
            clientApprovedTaxableFringeBenefit: rowData.clientApprovedTaxableFringeBenefit || false,
            accountantPostedTaxableFringeBenefit: rowData.accountantPostedTaxableFringeBenefit || false,
            memoOrDescription: rowData.memo,
        };

        this.props.LedgerUpdate(data, this.state.companyId);
        this.setState({
            editIndex1: -1,
            editIndex: -1,
            editData: null
        });
    }

    onRowCancelClick = (accountIndex, rowIndex, rowData) => {
        var data = [...this.state.lst_GL];
        let editData = this.state.editData;
        data[accountIndex].data[rowIndex].vendor1099 = editData.vendor1099;
        data[accountIndex].data[rowIndex].taxableFringeBenefit = editData.tfb;
        data[accountIndex].data[rowIndex].needsBetterDescription = editData.nbd;
        data[accountIndex].data[rowIndex].transferCodedIncorrectly = editData.tci;
        data[accountIndex].data[rowIndex].accountantComment = editData.ac;
        data[accountIndex].data[rowIndex].internalNotes = editData.in;
        data[accountIndex].data[rowIndex].newAccountID = editData.newAccId;
        data[accountIndex].data[rowIndex].newAccountName = editData.newAccName;
        data[accountIndex].data[rowIndex].cpaReview = editData.cpa;
        data[accountIndex].data[rowIndex].pcCheck = editData.pc;
        this.setState({
            lst_GL: data,
            editIndex1: -1,
            editIndex: -1,
            editData: null
        });
    }

    handalAccountDropdown = (e, accountIndex, rowIndex, rowData) => {

        if (e.value == null || e.value == 0) {
            //let obj = this.state.accounts.find(x => x.accountID == e.target.value);
            var data = [...this.state.lst_GL];
            data[accountIndex].data[rowIndex].newAccountID = null;
            data[accountIndex].data[rowIndex].newAccountName = null;
            this.setState({ lst_GL: data, selectedAccount: e });
        }
        else {
            let obj = this.state.accounts.find(x => x.accountID == e.value);
            var data = [...this.state.lst_GL];
            data[accountIndex].data[rowIndex].newAccountID = e.value;
            data[accountIndex].data[rowIndex].newAccountName = obj.name;
            this.setState({ lst_GL: data, selectedAccount: e });
        }

    }

    handalCheckbox = (e, accountIndex, rowIndex, rowData) => {
        var data = [...this.state.lst_GL];
        data[accountIndex].data[rowIndex][e.target.name] = e.target.checked;
        this.setState({ lst_GL: data });
    }

    handalTextArea = (e, accountIndex, rowIndex, rowData) => {
        var data = [...this.state.lst_GL];
        data[accountIndex].data[rowIndex][e.target.name] = e.target.value;
        this.setState({ lst_GL: data });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ledgers !== undefined && nextProps.ledgers !== this.props.ledgers) {
            let data = nextProps.ledgers;
            if (data.transactionStatus == 0) {

                let type = [];
                // type.push({ value: 'All', label: 'All' });
                if (data.returnObject.transactionTypeList != null) {
                    // type.push({ value: 'All', label: 'All' });
                    type = data.returnObject.transactionTypeList.map(item => {
                        return { value: item, label: item }
                    });
                }

                var glData = [...data.returnObject.generalLedger];
                if (this.state.collapsall) {
                    glData.forEach(element => { element.isExpand = false });
                }

                if (data.returnObject.reviewList.length > 0) {
                    toast.success("Report has been reviewed for selected or in between date range.");
                    this.setState({ ReviewList: data.returnObject.reviewList, ShowReview: true })
                }

                type.splice(0, 0, { value: 'All', label: 'All' });
                this.setState({
                    loading: false,
                    LedgerDetailLoading: false,
                    TransType: type,

                    isReviewCompleted: data.returnObject.isReviewCompleted,
                    syncFrequency: data.returnObject.syncFrequency,
                    lst_GL: glData && glData.length > 0 ? glData : []
                });
                this.lastSyncDetails(this.state.companyId);
            }
            else {
                this.setState({ LedgerDetailLoading: false });
                toast.error(`${data.resultMsg}`)
            }
        }
        if (nextProps.accounts != undefined && nextProps.accounts !== this.props.accounts) {

            let searchableAccounts = [];
            if (nextProps.accounts.data != null && nextProps.accounts.data.length > 0) {
                searchableAccounts = _.sortBy(nextProps.accounts.data, ['accountNumber', 'name']).map((value, index) => (
                    { value: value.accountID, label: (value.accountNumber != null ? value.accountNumber + ' ' + value.name : value.name) }
                ))
            }
            let defaultValue = { value: 0, label: "Select" };
            searchableAccounts.unshift(defaultValue);
            this.setState({
                accounts: nextProps.accounts.data,
                DisplayAccounts: searchableAccounts
            });
        }

        if (nextProps.CommonUpdate !== undefined && nextProps.CommonUpdate !== this.props.CommonUpdate) {
            if (nextProps.CommonUpdate.transactionStatus === 0) {
                if (this.state.IsStartRewViewProcessing) {
                    this.setState({ IsStartRewViewProcessing: false, IsMarkReviewProcessing: false, isReviewCompleted: false }, () => {
                        toast.success("General ledger start reviewing.");
                    });
                }
                else {
                    this.setState({ IsStartRewViewProcessing: false, IsMarkReviewProcessing: false, isReviewCompleted: true }, () => {
                        toast.success("General ledger mark as reviewed.");
                    });
                }
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextProps.CommonUpdate.resultMsg}`); });
            }
        }

        if (nextProps.GetGeneralReportNotesData !== undefined && nextProps.GetGeneralReportNotesData != this.props.GetGeneralReportNotesData) {

            if (nextProps.GetGeneralReportNotesData.transactionStatus === 0) {
                if (nextProps.GetGeneralReportNotesData.returnObject != null) {
                    this.setState({ loading: false, Notes: nextProps.GetGeneralReportNotesData.returnObject.notes });
                } else {
                    this.setState({ loading: false, Notes: "" });
                }
            }
        }
        if (nextProps.UpdateGeneralReportNotes !== undefined && nextProps.UpdateGeneralReportNotes !== this.props.UpdateGeneralReportNotes) {

            if (nextProps.UpdateGeneralReportNotes.transactionStatus === 0) {
                this.setState({ editNotes: false, Notes: this.state.prevNotes }, () => { toast.success(`${nextProps.UpdateGeneralReportNotes.resultMsg}`); });
                this.props.GetGeneralReportNotes(this.state.companyId, "GeneralLedger");
            }
            else {
                this.setState({ loading: false }, () => { toast.error(`${nextProps.UpdateGeneralReportNotes.resultMsg}`); });
            }
        }

        if (nextProps.LEDGER_UPDATE != undefined && nextProps.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
            if (nextProps.LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.success("Record updated successfully");
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.LEDGER_UPDATE.resultMsg}`);
                });

            }
        }

        if (nextProps.BATCH_LEDGER_UPDATE != undefined && nextProps.BATCH_LEDGER_UPDATE !== this.props.BATCH_LEDGER_UPDATE) {
            if (nextProps.BATCH_LEDGER_UPDATE.transactionStatus === 0) {
                this.setState({
                    loading: true, editData: null, editIndex: -1, SelectedRecords: [], SelectALL: false, selectedBatchAccountId: { value: 0, label: "Select" }
                }, () => {
                    toast.success("Record updated successfully");
                    this.getGLData();
                });
            }
            else {
                this.setState({
                    loading: false, SelectedRecords: [], SelectALL: false
                }, () => {
                    toast.error(`${nextProps.BATCH_LEDGER_UPDATE.resultMsg}`);
                });

            }
        }

        if (nextProps.companyDeatils != undefined) {
            if (nextProps.companyDeatils.quickbooksDataSyncEndingYear != "") {
                this.setState({
                    endingYear: nextProps.companyDeatils.quickbooksDataSyncEndingYear
                });
            }
        }
    }

    ApplyDate = () => {
        this.setState({
            loading: true,
            searchDate: "",
            SearchDateOBJ: {
                from: null,
                to: null
            }
        }, () => {
            this.getGLData();
        });
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter === "eDate") {
                control = this.eDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }
    handleDateChange = (e, target) => {
        if (target === "fromDate") {

            this.setState({ sDate: new Date(e), selectedDateRange: { startDate: moment(new Date(e)).format("MM/DD/YYYY"), endDate: moment(this.state.eDate).format("MM/DD/YYYY") } })
        }
        else if (target === "uptoDate") {
            this.setState({ eDate: new Date(e), selectedDateRange: { startDate: moment(this.state.sDate).format("MM/DD/YYYY"), endDate: moment(new Date(e)).format("MM/DD/YYYY") } })
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.GetGeneralReportNotes(user.companyId, "GeneralLedger")
            this.props.Accounts(this.state.companyId);
            this.lastSyncDetails(this.state.companyId);
            this.props.getCompnay(user.companyId);

        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.endingYear !== prevState.endingYear && !this.state.datesInitialized) {
            if (this.state.endingYear) {
                this.updateDateAndFetchData();
            } else {
                this.initializeDates();
            }
        }
    }

    initializeDates() {
        let { sDate, eDate } = this.state;

        if (!sDate || !eDate) {
            eDate = new Date();
            sDate = new Date(eDate.getFullYear(), 0, 1);

            if (eDate.getMonth() <= 2) {
                sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
                eDate = new Date(eDate.getFullYear() - 1, 11, 31);
            } else {
                sDate = new Date(eDate.getFullYear(), 0, 1);
                eDate = GetLastQuarterEndDate();
            }
        }

        if (!isNaN(sDate.getTime()) && !isNaN(eDate.getTime())) {
            this.setState({ sDate, eDate, datesInitialized: true }, () => {
                console.log('Initialized Dates:', sDate, eDate);
                this.getGLData();
                this.hasFetchedGLData = true;
            });
        } else {
            console.error('Invalid Dates:', { sDate, eDate });
        }
    }

    updateDateAndFetchData() {
        let { sDate, eDate, endingYear } = this.state;

        if (!sDate || !eDate) {
            eDate = new Date();
            sDate = new Date(eDate.getFullYear(), 0, 1);

            if (eDate.getMonth() <= 2) {
                sDate.setFullYear(eDate.getFullYear() - 1, 0, 1);
                eDate = new Date(eDate.getFullYear() - 1, 11, 31);
            } else {
                sDate = new Date(eDate.getFullYear(), 0, 1);
                eDate = GetLastQuarterEndDate();
            }
        }

        if (endingYear) {
            const adjustedDates = this.adjustDatesToEndingYear(sDate, eDate, endingYear);
            sDate = adjustedDates.sDate;
            eDate = adjustedDates.eDate;
        }


        console.log('Updated Dates:', { sDate, eDate, endingYear });

        const datesHaveChanged = this.state.sDate !== sDate || this.state.eDate !== eDate;

        if (datesHaveChanged) {
            this.setState({ sDate, eDate, datesInitialized: true }, () => {
                if (!this.glDataFetched) {
                    this.glDataFetched = true;
                    console.log('Fetching GL Data - Date Change:', { sDate, eDate });
                    this.getGLData();
                }
            });
        } else {
            if (!this.glDataFetched) {
                console.log('Fetching GL Data - No Date Change:', { sDate, eDate });
                this.getGLData();
            }
        }
    }

    componentWillUnmount() {
        this.glDataFetched = false;
        this.hasFetchedGLData = false;
    }

    UpdateItemCommentCount = (id) => {
        let item = this.state.lst_GL;
        item.forEach(function (records) {

            records.data.forEach(function (details) {
                if (details.id === id) {
                    details.commentCount++
                }
            });
        });

        this.setState({ lst_GL: item });
    }


    CloseReview = () => {
        this.setState({ ShowReview: false });
    }

    editNotesData = () => {
        this.setState({ editNotes: true, prevNotes: this.state.Notes });
    }

    handalNotesTextArea = (e) => {
        this.setState({ Notes: e.target.value });
    }
    UpdateNotes = () => {

        var obj =
        {
            "companyID": this.state.companyId,

            "reportType": "GeneralLedger",
            "notes": this.state.Notes
        }

        this.setState({
            // loading: true
            loading: false
        }, () => {
            this.props.InsertUpdateGeneralReportNotes(obj)
        });

    }
    onClickCloseButtonDate = (e) => {
        this.setState({ editNotes: false, Notes: this.state.prevNotes });
    }
    onCheckboxClick = (e, item) => {

        e.stopPropagation();


        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.id) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.id);
            }
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.id), 1);
            }
        }

        let all = (data.length == this.state.lst_GL.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1, editIndex1: -1 });

    }
    SelectALL = (e) => {

        if (this.state.lst_GL.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.lst_GL;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].id);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }

    OnAssignNewAccount = () => {
        let selectedData = this.state.SelectedRecords;
        let selectedBatchAccountId = this.state.selectedBatchAccountId;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }

        if (selectedBatchAccountId.value == null || selectedBatchAccountId.value == 0) {
            toast.error("Please select account");
            return false;
        }

        let body = {
            // "ids": selectedData,
            // "reportType": ReportType.UnCategorizeTransaction,
            // "companyID": this.state.companyId
            "txnId": selectedBatchAccountId.value,
            "glIds": selectedData
        }

        this.setState({ loading: true }, () => {
            this.props.UpdateBatchNewAccount(body);
        });
    }

    handalBatchUpdateAccountDropdown = (e) => {

        if (e.value == null || e.value == 0) {
            // var data = [...this.state.lst_GL];
            // data[accountIndex].data[rowIndex].newAccountID = null;
            // data[accountIndex].data[rowIndex].newAccountName = null;
            this.setState({ selectedBatchAccountId: e });
        }
        else {
            // let obj = this.state.accounts.find(x => x.accountID == e.value);
            // var data = [...this.state.lst_GL];
            // data[accountIndex].data[rowIndex].newAccountID = e.value;
            // data[accountIndex].data[rowIndex].newAccountName = obj.name;
            this.setState({ selectedBatchAccountId: e });
        }
    }

    render() {
        // var date = new Date(this.state.lastSyncObj.logDate);
        let selectedItem = this.state.SelectedRecords;
        return (
            <React.Fragment>
                {this.state.ShowReview ?
                    <ReviewPopup data={this.state.ReviewList} CloseReview={this.CloseReview}></ReviewPopup> : ""}
                {this.state.ShowHistory ?
                    <LogHistory
                        Module={ModuleType.GeneralReportType}
                        CompanyId={this.state.companyId}
                        RecordId={this.state.HistoryRecordId}
                        CloseHistory={this.CloseHistory}
                    ></LogHistory>
                    : ""}
                {this.state.ShowComment ? <CommonComments
                    CloseComments={this.CloseComments}
                    ColorCode={this.state.ColorCode}
                    ModuleType={ModuleType.GeneralReportType}
                    ReportType={ReportType.GL}
                    CompanyId={this.state.companyId}
                    RecordId={this.state.CommentReferenceId}
                    IsEmbeddedPage={this.state.IsEmbeddedPage}
                    UpdateItemCommentCount={this.UpdateItemCommentCount}
                >
                </CommonComments> : ""}
                {this.state.isOpenDatepicker &&
                    <QuaterDatePickerModel
                        options={this.state.quater}
                        onDatepickerCancel={this.onDatepickerCancel}
                        sDate={this.state.sDate}
                        eDate={this.state.eDate}
                    >
                    </QuaterDatePickerModel>

                }

                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | General Ledger Report</h2>
                </div>
                <div className="padding-box app-wrapper general-ledger-report">
                    <div className="col-md-12" style={{ paddingBottom: 25, marginLeft: -22 }}>
                        <div className="left-box">

                            {!this.state.editNotes ?
                                <div className="col-md-12">
                                    <h5>Notes: {this.state.Notes}
                                        <span className="" onClick={(e) => this.editNotesData()}>
                                            <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer"></i>
                                        </span>
                                    </h5>
                                </div>
                                :
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-1" style={{ marginTop: "5px" }}>
                                            <h5>Notes:</h5>
                                        </div>
                                        <div className="col-md-7" style={{ marginLeft: "-60px" }}>
                                            <Textarea className="placheholder-color"
                                                value={this.state.Notes} name="Notes"
                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                resize={"false"} placeholder=""
                                                onChange={(e) => this.handalNotesTextArea(e)} />
                                        </div>
                                        <div className="col-md-2" style={{ marginLeft: "-10px", marginTop: "4px" }}>
                                            {/* <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.UpdateNotes()} >Save</Button> */}
                                            <Button className="jr-btn close-btn" onClick={(e) => this.UpdateNotes(e)}><CheckIcon /></Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Button className="jr-btn close-btn" onClick={(e) => this.onClickCloseButtonDate(e)}><ClearIcon /></Button>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="select-quater-box">
                                <p className="sub-title">Date Selection</p>
                                <div className="row">
                                    <div className="col-sm-4 col-md-12 ">
                                        <div className="datepicker-box">
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.sDate}
                                                    onChange={(e) => this.handleDateChange(e, 'fromDate')}
                                                    name="fromDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    disabledKeyboardNavigation
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    maxDate={this.state.endingYear ? new Date(`${this.state.endingYear}-12-31`) : this.state.eDate}
                                                    ref={this.sDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                            <div className="to-box">
                                                <span className="text-center ledgerto-text">To</span>
                                            </div>
                                            <div className="date-box">
                                                <DatePicker
                                                    selected={this.state.eDate}
                                                    onChange={(e) => this.handleDateChange(e, 'uptoDate')}
                                                    name="uptoDate"
                                                    id="date"
                                                    required={true}
                                                    margin="normal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    yearDropdownItemNumber={5}
                                                    disabledKeyboardNavigation
                                                    //minDate={this.state.sDate}
                                                    maxDate={this.state.endingYear ? new Date(`${this.state.endingYear}-12-31`) : this.state.eDate}
                                                    ref={this.eDateRef}
                                                />
                                                <div className="date-icon" onClick={(e) => this.handleFocus(e, 'eDate')}>
                                                    <i className="zmdi zmdi-calendar-note"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="genera-apply-btn" style={{ marginTop: "5px" }}>
                                            <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={(e) => this.ApplyDate()} >apply</Button>

                                        </div>

                                    </div>
                                </div>
                                {/* <button className="prev-next-btn" onClick={() => this.onPrevQuater()}>
                                    <i className="zmdi zmdi-chevron-left"></i>Prev
                                </button>
                                <span className="date-text">{`Quarter ${this.state.quater.quater}, ${this.state.quater.year}`}</span>
                                <button className="prev-next-btn" onClick={() => this.onNextQuater()}>
                                    Next <i className="zmdi zmdi-chevron-right"></i>
                                </button> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p className="sub-title">Filter Record by Following Criteria</p>
                            <div className="check-box-main">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="TFB" type="checkbox" checked={this.state.TFB} onChange={() => this.onTFBChange(!this.state.TFB)} />
                                                        <label for="TFB">
                                                            <p className="checkbox-title">TFB</p>
                                                            <p className="parag-text">Taxable Fringe Benefit</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td>
                                            <div className="checkbox pointer" >
                                                <div className="chiller_cb">
                                                    <input id="VENDOR1099" type="checkbox" checked={this.state.VENDOR1099} onChange={() => this.onVendor1099Change(!this.state.VENDOR1099)} />
                                                    <label for="VENDOR1099">
                                                        <p className="checkbox-title">1099</p>
                                                        <p className="parag-text">Vendor 1099</p>
                                                    </label>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </td> */}
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="NBD" type="checkbox" checked={this.state.NBD} onChange={() => this.onNBDChange(!this.state.NBD)} />
                                                        <label for="NBD">
                                                            <p className="checkbox-title">NBD</p>
                                                            <p className="parag-text">Needs Better Description</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>

                                            </td>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="TCI" type="checkbox" checked={this.state.TCI} onChange={() => this.onTCIChange(!this.state.TCI)} />
                                                        <label for="TCI">
                                                            <p className="checkbox-title">TCI</p>
                                                            <p className="parag-text">Transfer Coded Incorrectly</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="AC" type="checkbox" checked={this.state.AC} onChange={() => this.onACChange(!this.state.AC)} />
                                                        <label for="AC">
                                                            <p className="checkbox-title">QC</p>
                                                            <p className="parag-text">Questions and Comments</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="IN" type="checkbox" checked={this.state.IN} onChange={() => this.onINChange(!this.state.IN)} />
                                                        <label for="IN">
                                                            <p className="checkbox-title">IN</p>
                                                            <p className="parag-text">Internal Notes</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="PC" type="checkbox" checked={this.state.PC} onChange={() => this.onPCChange(!this.state.PC)} />
                                                        <label for="PC">
                                                            <p className="checkbox-title">PC</p>
                                                            <p className="parag-text">Payroll Check</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="CPA" type="checkbox" checked={this.state.CPA} onChange={() => this.onCPAChange(!this.state.CPA)} />
                                                        <label for="CPA">
                                                            <p className="checkbox-title">CPA</p>
                                                            <p className="parag-text">CPA Review</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="BankCCTrans" type="checkbox" checked={this.state.BankCCTrans} onChange={() => this.onBanckCCTranChange(!this.state.BankCCTrans)} />
                                                        <label for="BankCCTrans">
                                                            <p className="checkbox-title show-credit-text">Show Bank and Credit Card Transactions</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="pcTransactionCheck" type="checkbox" checked={this.state.pcTransactionCheck} onChange={() => this.onPCTChange(!this.state.pcTransactionCheck)} />
                                                        <label for="pcTransactionCheck">
                                                            <p className="checkbox-title">PCT</p>
                                                            <p className="parag-text">Show Payroll Check Transaction</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox pointer" >
                                                    <div className="chiller_cb">
                                                        <input id="APA" type="checkbox" checked={this.state.APA} onChange={() => this.onAPAChange(!this.state.APA)} />
                                                        <label for="APA">
                                                            <p className="checkbox-title">APA</p>
                                                            <p className="parag-text">Accounts Payable Account</p>
                                                        </label>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12">
                                    <div className="check-box-main collapse-box">
                                        <div className="checkbox pointer" >
                                            <div className="chiller_cb">
                                                <input id="collapsall" type="checkbox" checked={this.state.collapsall} onChange={() => this.onCollapseAllChange(!this.state.collapsall)} />
                                                <label for="collapsall">
                                                    <p className="checkbox-title">Collapse All</p>
                                                </label>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="checkbox pointer" >
                                            <div className="chiller_cb">
                                                <input id="expandall" type="checkbox" checked={this.state.expandall} onChange={() => this.onExpandAllChange(!this.state.expandall)} />
                                                <label for="expandall">
                                                    <p className="checkbox-title">Expand All</p>
                                                </label>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-3 pt-4">
                            <div className="Last_Sync_GL">
                                {/* timedate-box */}
                                <p className="Font15PX"><strong>Last Sync: </strong> <span>
                                    {/* {moment(date).format("MM/DD/YY HH:mm A")} CST */}
                                    {this.state.LastUpdated}
                                </span></p>
                            </div>
                            <div className="button-right float-right">
                                {this.state.loading === false ?
                                    <React.Fragment>
                                        {this.state.isReviewCompleted ?
                                            <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onStartReview()} >Start Review</Button>
                                            :
                                            <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onMarkReviewd()} >Stop Review</Button>
                                        }
                                    </React.Fragment>

                                    : ""}
                                <FontTooltip id="tooltip-controlled" title="Get the latest data from the quickbooks online for selected quater." placement="top-start">
                                    <React.Fragment>
                                        {this.state.syncFrequency == "Disabled" ?
                                            <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" disabled><i className="zmdi zmdi-refresh "></i>Refresh Data</Button>
                                            :
                                            <Button variant="raised" className="greencolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onRefreshDataClick()}><i className="zmdi zmdi-refresh "></i>Refresh Data</Button>
                                        }
                                    </React.Fragment>
                                </FontTooltip>
                            </div>


                        </div>
                        <div className="col-md-12">
                            <div className="row justify-content-end d-flex align-items-center pr-3">
                                <div class="col-md-auto" style={{ paddingTop: '17px', margin: '0px -20px' }}> <p> <strong> New Account  </strong> : </p></div>
                                <div class="col-md-3">

                                    <RSelect
                                        required={true}
                                        name="BatchNewAccountID"
                                        value={this.state.selectedBatchAccountId}
                                        onChange={(e) => this.handalBatchUpdateAccountDropdown(e)}
                                        options={this.state.DisplayAccounts}
                                        //defaultValue={{ value: 0, label: "select" }}
                                        placeholder="Select"
                                    />
                                </div>
                                <div>
                                    <Button variant="raised" className=" jr-btn graycolor text-white refresh-btn" onClick={() => this.OnAssignNewAccount()} >Save</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.loading || this.state.LedgerDetailLoading ? <CircularProgress /> :
                        <div className="table-responsive-material">
                            {/* <TableScrollbar rows={10}> */}
                            <table className={`table data-table-box ${this.state.collapsall ? 'table-box' : ''}`}>
                                <thead>
                                    <tr className="header-bg-color">
                                        <th className={`td-width ${this.state.sortField == 'date' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('date')}>
                                            <p className="table-th-title">Date</p>
                                            <div className="input-box" onClick={(e) => e.stopPropagation()}>
                                                <FontTooltip id="tooltip-icon" title={this.state.searchDate} placement="right">
                                                    <TextField
                                                        name="searchDate"
                                                        id="searchDate"
                                                        value={this.state.searchDate}
                                                        onClick={(e) => this.onSearchDateFocus()}
                                                        className=""
                                                        placeholder="Search Date"
                                                        type="type"
                                                    />
                                                </FontTooltip>
                                            </div>
                                        </th>
                                        <th className={`td-width ${this.state.sortField == 'transactionType' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('transactionType')}>
                                            <p className="table-th-title"> Type</p>
                                            <div className="input-box dropdown-color" onClick={(e) => e.stopPropagation()}>
                                                <RSelect
                                                    value={this.state.searchTranType}
                                                    onChange={(e) => this.searchTranTypeChange(e)}
                                                    options={this.state.TransType}
                                                    defaultValue={this.state.searchTranType}
                                                    placeholder="Select Type"
                                                />
                                            </div>
                                        </th>
                                        <th className={`td-width ${this.state.sortField == 'num' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('num')}>
                                            <p className="table-th-title">Num</p>
                                            <div className="input-box" onClick={(e) => e.stopPropagation()}>
                                                <TextField
                                                    name="searchNum"
                                                    id="searchNum"
                                                    value={this.state.searchNum}
                                                    onChange={(e) => this.setState({ searchNum: e.target.value })}
                                                    onKeyDown={(e) => this.onSearchBoxEnter(e)}
                                                    className=""
                                                    placeholder="Search Num"
                                                    type="number"
                                                />
                                            </div>
                                        </th>
                                        <th className={`td-width ${this.state.sortField == 'name' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}>
                                            <p className="table-th-title">Name</p>
                                            <div className="input-box" onClick={(e) => e.stopPropagation()}>
                                                <TextField
                                                    name="searchName"
                                                    id="searchName"
                                                    value={this.state.searchName}
                                                    onChange={(e) => this.setState({ searchName: e.target.value })}
                                                    onKeyDown={(e) => this.onSearchBoxEnter(e)}
                                                    className=""
                                                    placeholder="Search Name"
                                                    type="text"
                                                />
                                            </div>
                                        </th>
                                        <th className={`th-width-box ${this.state.sortField == 'memo' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('memo')}>
                                            <p className="table-th-title">Memo/Description</p>
                                            <div className="input-box" onClick={(e) => e.stopPropagation()}>
                                                <TextField
                                                    name="searchMemo"
                                                    id="searchMemo"
                                                    value={this.state.searchMemo}
                                                    onChange={(e) => this.setState({ searchMemo: e.target.value })}
                                                    onKeyDown={(e) => this.onSearchBoxEnter(e)}
                                                    className=""
                                                    placeholder="Search Memo/Descripton"
                                                    type="text"
                                                />
                                            </div>
                                        </th>
                                        <th className={`td-width ${this.state.sortField == 'amount' ?
                                            this.state.sortFormat == 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('amount')}>
                                            <p className="table-th-title"> Amount </p>
                                            <div className="input-box" onClick={(e) => e.stopPropagation()}>
                                                <TextField
                                                    name="searchAmt"
                                                    id="searchAmt"
                                                    value={this.state.searchAmt}
                                                    onChange={(e) => this.setState({ searchAmt: e.target.value })}
                                                    onKeyDown={(e) => this.onSearchBoxEnter(e)}
                                                    className=""
                                                    placeholder="Search Amount"
                                                    type="number"
                                                />
                                            </div>
                                        </th>
                                        <th className={`td-width ${this.state.sortField === 'newAccountName' ?
                                            this.state.sortFormat === 'A'
                                                ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('newAccountName')}>
                                            <p className="table-th-title">  New Account</p>
                                            <div className="input-box" onClick={(e) => e.stopPropagation()}>
                                                <TextField
                                                    name="searchNewAccount"
                                                    id="searchNewAccount"
                                                    value={this.state.searchNewAccount}
                                                    onChange={(e) => this.setState({ searchNewAccount: e.target.value })}
                                                    onKeyDown={(e) => this.onSearchBoxEnter(e)}
                                                    className=""
                                                    placeholder="Search New Account"
                                                    type="text"
                                                />
                                            </div>
                                        </th>
                                        <th>TFB</th>
                                        {/* <th>1099</th> */}
                                        <th>NBD</th>
                                        <th>TCI</th>
                                        <th>PC</th>
                                        <th>CPA</th>
                                        <th>QC</th>
                                        <th>IN</th>
                                        <th><img src="/vendors/clock.png" alt=""></img></th>
                                        <th className="td-width-90 text-center">
                                            <label className="container101 header-check ">
                                                {this.state.SelectALL ?
                                                    <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                    :
                                                    <input className="second-check-box" type="checkbox" onClick={(e) => this.SelectALL(e)} />
                                                }
                                                <span className="checkmark101 white_border"></span>
                                            </label>
                                        </th>
                                        <th className="td-width-90 text-center"><img src="/vendors/WhiteCommnet.png" alt=""></img></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <React.Fragment>
                                        {this.state.lst_GL.map((account, i) => (
                                            <React.Fragment key={i}>
                                                <tr className={account.isExpand ? "bg-active" : ""} onClick={() => this.onRowExpandCollapseClick(i, account)}>
                                                    <td className="title-name" colSpan="17">
                                                        {
                                                            account.isExpand ? <i className="zmdi zmdi-minus-circle-outline"></i> : <i className="zmdi zmdi-plus-circle-o"></i>
                                                        }
                                                        <span className="account-text">{account.account}</span>
                                                    </td>
                                                </tr>
                                                {account.isExpand && account.isBeginningAmount &&
                                                    <tr>
                                                        <td colSpan="5" className="table-sub-title">Beginning Balance</td>
                                                        <td className="text-right ">{numberWithCommas(account.beginningAmount)}</td>
                                                        <td colSpan="17"></td>
                                                    </tr>}
                                                {account.isExpand && <React.Fragment>
                                                    {(account.data && account.data.length > 0) && account.data.map((item, j) => (
                                                        <React.Fragment key={j}>
                                                            {/* {((this.state.editIndex1 !== i && (this.state.editIndex !== j || this.state.editIndex === j)) || (this.state.editIndex1 === i && this.state.editIndex !== j)) && */}
                                                            <tr className={item.vendor1099 ? 'greenrow' : ''} onClick={(e) => this.onRowClick(e, i, j, item)}>
                                                                <React.Fragment>
                                                                    <td>{moment(item.date).format("MM/DD/YYYY")}</td>
                                                                    <td>{item.transactionType}</td>
                                                                    <td>
                                                                        <a className="anchor-link" onClick={(e) => this.onLinkClick(e, i, j, item)}>{item.num}<i className="zmdi zmdi-open-in-new ml-1"></i></a>
                                                                    </td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.memo}</td>
                                                                    <td className="text-right">{numberWithCommas(item.amount)}</td>
                                                                    <td>{item.newAccountName}</td>
                                                                    <td>{item.taxableFringeBenefit ?
                                                                        <FontTooltip id="tooltip-icon" title="Taxable Fringe Benefit" placement="top-start">
                                                                            <i className="zmdi zmdi-check"></i>
                                                                        </FontTooltip>
                                                                        : ''}</td>
                                                                    {/* <td>{item.vendor1099 ?
                                                                            <FontTooltip id="tooltip-icon" title="Vendor 1099" placement="top-start">
                                                                                <i className="zmdi zmdi-check"></i>
                                                                            </FontTooltip>
                                                                            : ''}</td> */}
                                                                    <td>{item.needsBetterDescription ?
                                                                        <FontTooltip id="tooltip-icon" title="Needs Better Description" placement="top-start">
                                                                            <i className="zmdi zmdi-check"></i>
                                                                        </FontTooltip>
                                                                        : ''}</td>
                                                                    <td>{item.transferCodedIncorrectly ?
                                                                        <FontTooltip id="tooltip-icon" title="Transfer Coded Incorrectly" placement="top-start">
                                                                            <i className="zmdi zmdi-check"></i>
                                                                        </FontTooltip>
                                                                        : ''}</td>
                                                                    <td>{item.pcCheck ?
                                                                        <FontTooltip id="tooltip-icon" title="Payroll Check" placement="top-start">
                                                                            <i className="zmdi zmdi-check"></i>
                                                                        </FontTooltip>
                                                                        : ''}</td>
                                                                    <td>{item.cpaReview ?
                                                                        <FontTooltip id="tooltip-icon" title="CPA Review" placement="top-start">
                                                                            <i className="zmdi zmdi-check"></i>
                                                                        </FontTooltip>
                                                                        : ''}</td>
                                                                    <td>
                                                                        <FontTooltip id="tooltip-icon" title={item.accountantComment} placement="top-start">
                                                                            <div>{item.accountantComment && 'QC'}</div>
                                                                        </FontTooltip>
                                                                    </td>

                                                                    <td>
                                                                        <FontTooltip id="tooltip-icon" title={item.internalNotes} placement="top-start">
                                                                            <div>{item.internalNotes && 'IN'}</div>
                                                                        </FontTooltip>
                                                                    </td>
                                                                    <td><img src="/vendors/Forma 1.png" alt="" onClick={(e) => this.LoadHistory(e, item)}></img></td>
                                                                    <td className="text-center">
                                                                        <label className="container101">
                                                                            {(this.state.SelectALL || selectedItem.length > 0 && (selectedItem.indexOf(item.id) > -1)) ?
                                                                                <input className="second-check-box" checked="checked" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                                :
                                                                                <input className="second-check-box" type="checkbox" onClick={(e) => this.onCheckboxClick(e, item)} />
                                                                            }
                                                                            <span className="checkmark101"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <div className="panel-commentCount col-md-2" >
                                                                            <img src="/vendors/Vector Smart Object.png" alt="" className={item.commentCount === 0 ? "disableCommentsOpacity" : ""} onClick={(e) => this.LoadComments(e, item)}></img>
                                                                            {item.unreviewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{item.unreviewCommentCount}</span>}
                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>
                                                            </tr>
                                                            {/* // } */}
                                                            {this.state.editIndex1 === i && this.state.editIndex === j &&
                                                                <tr className="editRowColor">
                                                                    <React.Fragment>
                                                                        {/* <td onClick={(e) => e.stopPropagation()}>{moment(item.date).format("MM/DD/YYYY")}</td>
                                                                        <td onClick={(e) => e.stopPropagation()}>{item.transactionType}</td>
                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                            <a className="anchor-link" onClick={(e) => this.onLinkClick(e, i, j, item)}>{item.num}<i className="zmdi zmdi-open-in-new ml-1"></i></a>
                                                                        </td>
                                                                        <td onClick={(e) => e.stopPropagation()}>{item.name}</td>
                                                                        <td onClick={(e) => e.stopPropagation()}>{item.memo}</td>
                                                                        <td className="text-right" onClick={(e) => e.stopPropagation()}>{numberWithCommas(item.amount)}</td> */}
                                                                        {/* <td></td>
                                                                        <td></td>
                                                                        <td></td> */}
                                                                        {/* <td></td>
                                                                        <td></td> */}
                                                                        <td colSpan="3">

                                                                            <label className="input-title">Questions and Comments:</label>

                                                                            <Textarea className="placheholder-color"
                                                                                value={item.accountantComment} name="accountantComment"
                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                resize={"false"} placeholder=""
                                                                                onChange={(e) => this.handalTextArea(e, i, j, item)} />


                                                                        </td>
                                                                        <td colSpan="3">

                                                                            <label className="input-title">Internal Notes:</label>


                                                                            <Textarea className="placheholder-color"
                                                                                value={item.internalNotes} name="internalNotes"
                                                                                style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                resize={"false"} placeholder=""
                                                                                onChange={(e) => this.handalTextArea(e, i, j, item)} />

                                                                        </td>

                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                            <label className="input-title"></label>
                                                                            <RSelect
                                                                                required={true}
                                                                                name="NewAccountID"
                                                                                value={this.state.selectedAccount}
                                                                                onChange={(e) => this.handalAccountDropdown(e, i, j, item)}
                                                                                options={this.state.DisplayAccounts}
                                                                                //defaultValue={{ value: 0, label: "select" }}
                                                                                placeholder="Select"
                                                                            />
                                                                        </td>
                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                            <label className="input-title"></label>
                                                                            <FontTooltip id="tooltip-icon" title="Taxable Fringe Benefit" placement="top">
                                                                                <div className="chiller_cb table-checkbox">
                                                                                    <input id="taxableFringeBenefit1" name="taxableFringeBenefit" type="checkbox" checked={item.taxableFringeBenefit} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="taxableFringeBenefit1"></label>
                                                                                    <span></span>
                                                                                </div>
                                                                            </FontTooltip>
                                                                        </td>
                                                                        {/* <td onClick={(e) => e.stopPropagation()}>
                                                                            <FontTooltip id="tooltip-icon" title="Vendor 1099" placement="top">
                                                                                <div className="chiller_cb table-checkbox">
                                                                                    <input id="vendor10991" name="vendor1099" type="checkbox" checked={item.vendor1099} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="vendor10991"></label>
                                                                                    <span></span>
                                                                                </div>
                                                                            </FontTooltip>
                                                                        </td> */}
                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                            <label className="input-title"></label>
                                                                            <FontTooltip id="tooltip-icon" title="Needs Better Description" placement="top">
                                                                                <div className="chiller_cb table-checkbox">
                                                                                    <input id="needsBetterDescription1" name="needsBetterDescription" type="checkbox" checked={item.needsBetterDescription} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="needsBetterDescription1"></label>
                                                                                    <span></span>
                                                                                </div>
                                                                            </FontTooltip>
                                                                        </td>
                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                            <label className="input-title"></label>
                                                                            <FontTooltip id="tooltip-icon" title="Transfer Coded Incorrectly" placement="top">
                                                                                <div className="chiller_cb table-checkbox">
                                                                                    <input id="transferCodedIncorrectly1" name="transferCodedIncorrectly" type="checkbox" checked={item.transferCodedIncorrectly} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="transferCodedIncorrectly1"></label>
                                                                                    <span></span>
                                                                                </div>
                                                                            </FontTooltip>
                                                                        </td>
                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                            <label className="input-title"></label>
                                                                            <FontTooltip id="tooltip-icon" title="Payroll Check" placement="top">
                                                                                <div className="chiller_cb table-checkbox">
                                                                                    <input id="pcCheck1" name="pcCheck" type="checkbox" checked={item.pcCheck} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="pcCheck1"></label>
                                                                                    <span></span>
                                                                                </div>
                                                                            </FontTooltip>
                                                                        </td>
                                                                        <td onClick={(e) => e.stopPropagation()}>
                                                                            <label className="input-title"></label>
                                                                            <FontTooltip id="tooltip-icon" title="CPA Review" placement="top">
                                                                                <div className="chiller_cb table-checkbox">
                                                                                    <input id="cpaReview1" name="cpaReview" type="checkbox" checked={item.cpaReview} onChange={(e) => this.handalCheckbox(e, i, j, item)} />
                                                                                    <label for="cpaReview1"></label>
                                                                                    <span></span>
                                                                                </div>
                                                                            </FontTooltip>
                                                                        </td>
                                                                        {/* <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td> */}
                                                                        <td colSpan="5" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(i, j, item)}>SAVE</Button>
                                                                            <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick(i, j, item)}>CANCEL</Button>

                                                                        </td>
                                                                    </React.Fragment>
                                                                </tr>
                                                            }
                                                            {/* {this.state.editIndex1 === i && this.state.editIndex === j &&
                                                                <tr className="editRowColor">
                                                                    <td colSpan="17">
                                                                        <div className="row">
                                                                            <div className="col-md-5">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <label className="input-title">Questions and Comments:</label>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <Textarea className="placheholder-color"
                                                                                            value={item.accountantComment} name="accountantComment"
                                                                                            style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                            resize={"false"} placeholder=""
                                                                                            onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <label className="input-title">Internal Notes:</label>
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <Textarea className="placheholder-color"
                                                                                            value={item.internalNotes} name="internalNotes"
                                                                                            style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                                                                                            resize={"false"} placeholder=""
                                                                                            onChange={(e) => this.handalTextArea(e, i, j, item)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowUpdateClick(i, j, item)}>SAVE</Button>
                                                                                <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancelClick(i, j, item)}>CANCEL</Button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>} */}
                                                        </React.Fragment>
                                                    ))}
                                                    {(!account.data || account.data.length === 0) &&
                                                        <tr>
                                                            <td className="text-center" colSpan="17">
                                                                No data found
                                                            </td>
                                                        </tr>
                                                    }
                                                </React.Fragment>}
                                                {account.isExpand &&
                                                    <tr>
                                                        <td colSpan="5" className="table-sub-title">Total for {account.account}</td>
                                                        <td className="text-right ">$
                                                            {/* total-amount-text */}
                                                            {account.totalAmount != null ?
                                                                <React.Fragment>
                                                                    {numberWithCommas(account.totalAmount)}
                                                                </React.Fragment>
                                                                : ""
                                                            }
                                                        </td>
                                                        <td colSpan="12"></td>
                                                    </tr>}
                                            </React.Fragment>
                                        ))}
                                        {this.state.lst_GL.length == 0 &&
                                            <tr>
                                                <td className="text-center" colSpan="17">
                                                    No data found
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                </tbody>
                            </table>
                            {/* </TableScrollbar> */}
                        </div>
                    }
                </div>
                {!this.state.loading && !this.state.LedgerDetailLoading &&
                    <div className="sticky-button">
                        <FontTooltip id="tooltip-icon" title="Collapse All" placement="top">
                            <Button variant="raised" className={`jr-btn text-white sticky-btn collapse-btn ${this.state.collapsall ? "bg-teal" : "bg-blue-grey"}`}
                                onClick={() => this.onCollapseAllChange(false)}>
                                <img src="/vendors/Collapse.png" alt=""></img>
                            </Button>
                        </FontTooltip>
                        <FontTooltip id="tooltip-icon" title="Expand All" placement="bottom">
                            <Button variant="raised" className={`jr-btn text-white sticky-btn expand-btn ${this.state.expandall ? "bg-teal" : "bg-blue-grey"}`}
                                onClick={() => this.onExpandAllChange(true)}>
                                <img src="/vendors/Expand.png" alt=""></img>
                            </Button>
                        </FontTooltip>
                    </div>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ledgers: state.ledgers,
        accounts: state.accounts.accountsData,
        CommonUpdate: state.ledgers.CommonUpdate,
        GetGeneralReportNotesData: state.reconcillition.GetGeneralReportNotesData,
        UpdateGeneralReportNotes: state.reconcillition.UpdateGeneralReportNotes,
        LEDGER_UPDATE: state.ledgers.LEDGER_UPDATE,
        BATCH_LEDGER_UPDATE: state.ledgers.BATCH_LEDGER_UPDATE,
        companyDeatils: state.companyReducer.companyDeatils
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        Accounts,
        GetLedgerDetail_New,
        LedgerUpdate,
        MarkReview,
        StartReview,
        GetGeneralReportNotes,
        InsertUpdateGeneralReportNotes,
        UpdateBatchNewAccount,
        getCompnay
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(NewLedgerPage);